/* eslint-disable no-else-return */
/* eslint-disable no-console */
/* eslint-disable no-return-await */
import fetchApi from '@/src/Middleware/fetchApi';
import { getCookies, setCookie } from '../../src/Utils/Cookies';

// const publicIp = require('public-ip');

const cookieExpiry = 60 * 60 * 24 * 1000 * 7;

const toggleLocationPrompt = () => {
  if (document.querySelector('body').classList.contains('prompt-popup')) {
    document.querySelector('body').classList.remove('prompt-popup');
  } else {
    document.querySelector('body').classList.add('prompt-popup');
  }
  return (dispatch) => {
    return dispatch({
      type: 'TOGGLE_LOCATION_PROMPT',
    });
  };
};

export const getUserLocation = () => {
  return async (dispatch) => {
    const { dkt_pincode: pincode, dkt_city: city, dkt_state: state } = getCookies();
    // const { CancelToken } = axios;

    if (pincode && city && state) {
      dispatch({
        type: 'GET_USER_LOCATION',
        payload: {
          pincode,
          city,
          state,
        },
      });

      return false;
    }

    let ipAddress = null;
    // try {
    // 	ipAddress = await publicIp.v4();
    // } catch (_) {}

    let userPincode = 560002;
    let userCity = 'Bengaluru';
    let userState = 'Karnataka';

    if (ipAddress) {
      const response = await fetchApi(null, '/api/pincode', {
        data: { ipAddress },
        timeout: 8000,
      });

      if (response?.status) {
        userPincode = response?.pincode || 560002;
        userCity = response?.city || 'Bengaluru';
        userState = response?.state || 'Karnataka';
      }
    }

    dispatch({
      type: 'GET_USER_LOCATION',
      payload: {
        pincode: userPincode,
        city: userCity,
        state: userState,
      },
    });

    setCookie('dkt_pincode', userPincode, {
      expires: new Date(new Date().getTime() + cookieExpiry),
    });
    setCookie('dkt_city', userCity, {
      expires: new Date(new Date().getTime() + cookieExpiry),
    });
    setCookie('dkt_state', userState, {
      expires: new Date(new Date().getTime() + cookieExpiry),
    });
  };
};

const updatePincode = ({ pincode, ctx }) => {
  return async (dispatch) => {
    const stateCity = await fetchApi(ctx, '/api/get-city-state', {
      data: {
        pincode,
      },
    });
    const { status, city, state, message } = stateCity;

    if (status && city && state) {
      setCookie('dkt_pincode', pincode, {
        expires: new Date(new Date().getTime() + cookieExpiry),
      });
      setCookie('dkt_city', city, {
        expires: new Date(new Date().getTime() + cookieExpiry),
      });
      setCookie('dkt_state', state, {
        expires: new Date(new Date().getTime() + cookieExpiry),
      });
      dispatch({
        type: 'GET_USER_LOCATION',
        payload: {
          pincode,
          city,
          state,
        },
      });

      dispatch({
        type: 'GET_ZIP_DETAILS',
        payload: {
          zipcodeDetails: 'no data',
        },
      });

      return {
        city,
        state,
        pincode,
        status: true,
      };
    } else {
      return {
        status: false,
        message,
      };
    }
  };
};

const getPincodeDetails = (pincode) => {
  return async (dispatch) => {
    dispatch({
      type: 'GET_ZIP_DETAILS',
      payload: {
        zipcodeDetails: null,
      },
    });
    return null;
  };
};

const getAddress = () => {
  return async (dispatch) => {
    const response = await fetchApi(null, '/api/customer/customeraddress');
    localStorage.setItem('addresses', response?.addresses?.length || 0);
    if (response?.status) {
      dispatch({
        type: 'GET_ADDRESS',
        payload: response?.addresses || [],
      });

      return true;
    } else {
      return false;
    }
  };
};

const locationPrompt = {
  getUserLocation,
  toggleLocationPrompt,
  updatePincode,
  getAddress,
  getPincodeDetails,
};

export default locationPrompt;
