/* eslint-disable consistent-return */
/* eslint-disable no-case-declarations */
import jsonQuery from 'json-query';
import Router from 'next/router';
import constants from '@/src/constants';
import { getProductUrl } from '../../src/Utils';
import { setCookie } from '../../src/Utils/Cookies';

const initialState = {
  isLoggedIn: false,
  toggle: false,
  verticalNavigation: false,
  pincode: null,
  zipcodedetails: 'no data',
  city: null,
  locationPrompt: false,
  locationPromptAddress: false,
  device: 'Web',
  orderTracking: false,
  orderTrackingLoader: false,
  cartAddressList: false,
  triggerChatbotLogin: false,
  voiceSearchError: false,
  productPage: {
    list: [],
    colorVariants: [],
    modelId: null,
    data: [],
    size: [],
    reviews: {},
    reviewsLoader: true,
    ratings: 0,
    ratingCount: 0,
    isPODAvailable: false,
    homeDelivery: [],
    collectOptions: [],
    lockerOptions: [],
    sizeStock: [],
    stockLoading: true,
    reviewToken: null,
    decaTabs: [],
    promiseSection: [],
    sizeGuide: {},
    fulfillerError: false,
    stockApiCount: 0,
    addToCartReco: false,
    productPageLoader: false,
    show404Page: false,
    redirectToOriginalUrl: false,
    multiAddToCartProducts: {},
    multiProductsList: [],
    selectedMultiProductData: [],
    promotion: [],
    questionAnswers: [],
    activeProduct: {},
    productsList: [],
    selectedArticle: {},
    multiProductButtonClick: false,
  },
  cartPage: {
    cartItems: [],
    couponList: [],
    cartCount: 0,
    cartLoader: true,
    isUpdating: false,
    cartTotal: 0,
    cartShippingCost: false,
    cartOldShippingCost: false,
    isDeliveryTypePickUp: false,
  },
  checkoutPage: {
    deliveryAddress: [],
    deliveryOptions: [],
    addressLoader: true,
    addAddressLoader: true,
    addDeliveryOptionLoader: false,
    razorpayOptions: {},
    paymentDowntime: {},
    paymentOption: {},
    onepayOption: {},
    paymentLoader: false,
    sortingPriority: null,
    isGooglePayAvailable: false,
    giftCards: [],
    giftCardCartTotal: 0,
    fullfillerConfigurations: null,
    checkoutError: false,
    availableIntentApps: [],
    isShippingAddressChanged: true,
    CREDEligibility: {
      status: false,
      description: '',
    },
  },
  myAccountPage: {
    digitalOrders: 'No Data',
    storeOrders: 'No Data',
    orderTrack: 'No Data',
    cancelReasons: 'No Data',
    employeeData: {},
    employeeDiscountLoader: true,
    orderDetailsText: [],
    sellersReturnInfo: [],
  },
  thankYouPage: {
    paymentStatus: null,
    thankYouLoader: true,
  },
  addnewaddress: false,
  reviewPopup: false,
  suggestionSearchtxt: false,
  isMobileNumber: 1,
  deliveryterms: null,
  footerWCMSData: null,
  purchaseorderinfromation: false,
  videoframe: false,
  verticalmenudata: { cms: null, pim: null },
  videoId: false,
  showSearchSuggestions: false,
  showTopProducts: false,
  numberOfTopProducts: 0,
  myProfilePopup: false,
  megaMenuData: [],
  newMegaMenuData: [],
  isPaymentTab: false,
  decaSlideModalData: {
    status: false,
    modelID: null,
  },
  categoryPage: {
    categoryPageLoader: false,
    show404Page: false,
    redirectToOriginalUrl: false,
    categoryBanners: {
      banners: [],
      floorPositions: [],
    },
  },
  shopPage: {
    shopPageLoader: false,
    show404Page: false,
    shopPage: {},
  },
  blogPage: {
    blogPageLoader: false,
    show404Page: false,
  },
  persoRecoContent: {
    persoRecoData: [],
    persoRecoTitle: null,
    persoRecoLoader: true,
  },
  multiproductContent: {
    multiproducts: [],
    multiCartLoader: true,
  },
  storeDetailsPage: {
    storedetail: {},
  },
  showRecentSuggestions: false,
  bnplModalToggle: false,
  searchBarKeys: {
    placeholder: '',
    keywords: [],
  },
  ribbonBanners: [],
  ribbonBannersInCategory: false,
};

const reducer = (state = initialState, { type, payload, click }) => {
  switch (type) {
    case 'TOGGLE':
      return {
        ...state,
        toggle: !state.toggle,
      };
    case 'TOGGLE_VERTICAL_NAVIGATION':
      return {
        ...state,
        verticalNavigation: !state.verticalNavigation,
      };
    case 'SEARCH_SUGGESTIONS_HIDE_SHOW':
      return {
        ...state,
        showSearchSuggestions: payload,
      };
    case 'SEARCH_TOP_PRODUCTS_HIDE_SHOW':
      return {
        ...state,
        showTopProducts: payload,
      };
    case 'NUMBER_TOP_PRODUCTS':
      return {
        ...state,
        numberOfTopProducts: payload,
      };

    case 'TOGGLE_RETURN_SLIDE':
      return {
        ...state,
        returnSlide: !state.returnSlide,
      };
    case 'DELIVERY_TERMS':
      return {
        ...state,
        deliveryterms: payload,
      };
    case 'TOGGLE_LOCATION_PROMPT':
      return {
        ...state,
        locationPrompt: !state.locationPrompt,
      };
    case 'TOGGLE_CART_ADDRESS_LIST':
      return {
        ...state,
        cartAddressList: !state.cartAddressList,
      };

    case 'CLOSE_CART_ADDRESS_LIST':
      return {
        ...state,
        cartAddressList: false,
      };

    case 'GET_ADDRESS':
      return {
        ...state,
        locationPromptAddress: payload,
      };
    case 'TOGGLE_ADD_NEW_ADDRESS':
      return {
        ...state,
        addnewaddress: !state.addnewaddress,
      };
    case 'TOGGLE_VIDEO_FRAME':
      return {
        ...state,
        videoframe: !state.videoframe,
        videoId: payload,
      };
    case 'GET_USER_LOCATION':
      return {
        ...state,
        pincode: payload.pincode,
        city: payload.city,
      };
    case 'GET_ZIP_DETAILS':
      return {
        ...state,
        zipcodedetails: payload.zipcodeDetails,
      };
    case 'PRODUCT_PAGE_LOADER':
      return {
        ...state,
        productPage: {
          ...state.productPage,
          productPageLoader: payload,
        },
      };
    case 'GET_PRODUCT_PAGE_DATA':
      return {
        ...state,
        productPage: {
          ...state.productPage,
          ...payload,
        },
      };
    case 'CHANGE_PRODUCT_COLOR':
      const data = jsonQuery(`[modelId=${payload.modelId}]`, {
        data: state.productPage.list,
      }).value;

      if (payload.changeHash) {
        Router.push('/p/[...product]', `${getProductUrl(data)}?id=${payload.modelId}&type=p`, {
          shallow: true,
          query: { type: 'p', modelId: payload.modelId },
        });
      }

      const size = data ? data.articles[0] : [];

      payload.callback(data, size);

      return {
        ...state,
        productPage: {
          ...state.productPage,
          modelId: payload.modelId,
          data,
          size,
        },
      };
    case 'CHANGE_PRODUCT_SIZE':
      return {
        ...state,
        productPage: {
          ...state.productPage,
          size: payload,
        },
      };
    case 'GET_REVIEWS_LOADER':
      return {
        ...state,
        productPage: {
          ...state.productPage,
          reviewsLoader: payload,
        },
      };
    case 'GET_REVIEWS':
      return {
        ...state,
        productPage: {
          ...state.productPage,
          reviews: payload,
        },
      };
    case 'TOGGLE_REVIEW_POPUP':
      if (payload) {
        return {
          ...state,
          productPage: {
            ...state.productPage,
            data: {
              ...state.productPage.data,
              modelId: payload,
            },
          },
          reviewPopup: payload || !state.reviewPopup,
        };
      }

      return {
        ...state,
        reviewPopup: !state.reviewPopup,
      };
    case 'SET_DATA_TO_REDUX':
      return {
        ...state,
        productPage: {
          ...state.productPage,
          decaTabs: payload.decaTabs,
          promiseSection: payload.promiseSection,
          sizeGuide: payload.sizeGuide,
          multiAddToCartProducts: payload.multiAddToCartProducts,
          promotion: payload.promotion,
        },
      };
    case 'SEARCH_SUGGESTIONS':
      return {
        ...state,
        suggestionSearchtxt: payload,
      };
    case 'TOGGLE_STOCK_LOADING':
      return {
        ...state,
        productPage: {
          ...state.productPage,
          stockLoading: payload,
        },
      };
    case 'ADD_TO_CART_POPUP':
      return {
        ...state,
        productPage: {
          ...state.productPage,
          addToCartReco: payload,
        },
      };

    case 'STORE_FOOTER_WCMS_DATA':
      return {
        ...state,
        footerWCMSData: payload,
      };

    case 'STORE_MEGA_MENU_DATA':
      return {
        ...state,
        megaMenuData: payload.data,
      };

    case 'STORE_NEW_MEGA_MENU_DATA':
      return {
        ...state,
        newMegaMenuData: payload.data,
      };

    case 'GET_CART_COUNT':
      return {
        ...state,
        cartPage: {
          ...state.cartPage,
          cartCount: payload,
        },
      };
    case 'TOGGLE_CART_LOADER':
      return {
        ...state,
        cartPage: {
          ...state.cartPage,
          cartLoader: payload,
        },
      };
    case 'CLEAR_CART': {
      return {
        ...state,
        cartPage: {
          ...initialState.cartPage,
          cartLoader: false,
        },
      };
    }

    case 'GET_CART':
      if (payload?.cartId) {
        setCookie('dkt_cartId', payload.cartId);
      }
      const couponCode = payload.discountType === constants.empCode ? constants.empCode : payload.couponCode;

      return {
        ...state,
        apiResponse: payload.apiResponse,
        cartPage: {
          ...state.cartPage,
          cartItems: {
            ...payload,
            couponCode, // Set the couponCode here
          },
          cartCount: payload.totalLineCount,
          cartTotal: payload.cartTotal + (!payload.isFreeShipping ? payload.shippingCost : 0),
          cartShippingCost: false,
        },
      };

    case 'CART_UPDATING':
      return {
        ...state,
        cartPage: {
          ...state.cartPage,
          isUpdating: payload,
        },
      };
    case 'UPDATE_CART_TOTAL':
      const { cartTotal, cartShippingCost, cartOldShippingCost } = state.cartPage;
      const { giftCardCartTotal } = state.checkoutPage;

      return {
        ...state,
        cartPage: {
          ...state.cartPage,
          cartOldShippingCost: payload?.cartOldShippingCost || cartOldShippingCost || false,
          cartTotal: payload.cartTotal,
          isDeliveryTypePickUp: payload.isDeliveryTypePickUp || false,
          cartShippingCost:
            typeof payload.cartShippingCost !== 'undefined' ? payload.cartShippingCost : cartShippingCost,
        },
        checkoutPage: {
          ...state.checkoutPage,
          giftCardCartTotal:
            typeof payload.giftCardCartTotal !== 'undefined'
              ? payload.giftCardCartTotal || giftCardCartTotal
              : cartTotal,
        },
      };
    case 'GET_COUPONS':
      return {
        ...state,
        cartPage: {
          ...state.cartPage,
          couponList: payload,
        },
      };

    case 'UPDATE_ADDRESS_TAB':
      return {
        ...state,
        updateAddressTabs: !state.updateAddressTabs,
      };
    case 'TOGGLE_ADDRESS_LOADER':
      return {
        ...state,
        checkoutPage: {
          ...state.checkoutPage,
          addressLoader: payload,
        },
      };
    case 'TOGGLE_ADD_ADDRESS_LOADER':
      return {
        ...state,
        checkoutPage: {
          ...state.checkoutPage,
          addAddressLoader: payload,
        },
      };
    case 'ADD_DELIVERY_OPTION':
      return {
        ...state,
        checkoutPage: {
          ...state.checkoutPage,
          addDeliveryOptionLoader: payload,
        },
      };
    case 'RAZORPAY_OPTIONS':
      return {
        ...state,
        checkoutPage: {
          ...state.checkoutPage,
          razorpayOptions: payload,
        },
      };
    case 'PAYMENT_INIT':
      return {
        ...state,
        checkoutPage: {
          ...state.checkoutPage,
          paymentOption: payload.razorpay,
          onepayOption: payload.onepay,
        },
      };

    case 'PAYMENT_DOWNTIME':
      return {
        ...state,
        checkoutPage: {
          ...state.checkoutPage,
          paymentDowntime: payload.downtime,
        },
      };
    case 'PAYMENT_LOADER':
      return {
        ...state,
        checkoutPage: {
          ...state.checkoutPage,
          paymentLoader: payload,
        },
      };
    case 'PAYMENT_STATUS':
      return {
        ...state,
        thankYouPage: {
          ...state.thankYouPage,
          paymentStatus: payload,
        },
      };
    case 'PAYMENT_STATUS_LOADER':
      return {
        ...state,
        thankYouPage: {
          ...state.thankYouPage,
          thankYouLoader: payload,
        },
      };
    case 'GET_DELIVERY_ADDRESS':
      return {
        ...state,
        checkoutPage: {
          ...state.checkoutPage,
          deliveryAddress: payload,
        },
      };
    case 'GET_DELIVERY_OPTIONS':
      return {
        ...state,
        checkoutPage: {
          ...state.checkoutPage,
          deliveryOptions: payload,
        },
      };
    case 'GET_CONFIGURATIONS_OPTIONS':
      return {
        ...state,
        checkoutPage: {
          ...state.checkoutPage,
          fullfillerConfigurations: payload,
        },
      };
    case 'GOOGLE_PAY_AVAILABLE':
      return {
        ...state,
        checkoutPage: {
          ...state.checkoutPage,
          isGooglePayAvailable: payload,
        },
      };
    case 'PAGINATE_STORE_ORDERS':
      return {
        ...state,
        paginateStoreOrders: !!click,
        storeordersrange: payload,
      };
    case 'PRODUCT_ORDER_INFORMATION':
      return {
        ...state,
        productorderinformation: payload,
      };
    case 'PURCHASE_ORDER_INFORMATION':
      return {
        ...state,
        purchaseorderinfromation: !state.purchaseorderinfromation,
      };
    case 'SET_DEVICE':
      return {
        ...state,
        device: payload,
      };
    case 'SET_CONTEXT_DEVICE':
      return {
        ...state,
        contextDevice: payload,
      };
    case 'ORDER_TRACKING':
      return {
        ...state,
        orderTracking: payload,
      };
    case 'ORDER_TRACKING_LOADER':
      return {
        ...state,
        orderTrackingLoader: payload,
      };
    case 'SET_ORDER_DETAILS_TEXT':
      return {
        ...state,
        myAccountPage: {
          ...state.myAccountPage,
          orderDetailsText: [...payload],
        },
      };
    case 'SET_SELLERS_RETURN_INFO':
      return {
        ...state,
        myAccountPage: {
          ...state.myAccountPage,
          sellersReturnInfo: payload,
        },
      };
    case 'MY_PROFILE_POPUP':
      return {
        ...state,
        myProfilePopup: !state.myProfilePopup,
      };
    case 'TRIGGER_CHATBOT_LOGIN':
      return {
        ...state,
        triggerChatbotLogin: payload,
      };
    case 'DIGITAL_ORDERS':
      return {
        ...state,
        myAccountPage: {
          ...state.myAccountPage,
          digitalOrders: payload,
        },
      };

    case 'EMPLOYEE_DISCOUNT_LOADER':
      return {
        ...state,
        myAccountPage: {
          ...state.myAccountPage,
          employeeDiscountLoader: payload,
        },
      };

    case 'EMPLOYEE_DISCOUNT':
      return {
        ...state,
        myAccountPage: {
          ...state.myAccountPage,
          employeeData: payload,
          employeeDiscountLoader: false,
        },
      };

    case 'STORE_ORDERS':
      return {
        ...state,
        myAccountPage: {
          ...state.myAccountPage,
          storeOrders: payload,
        },
      };
    case 'ORDER_TRACK_DETAILS':
      return {
        ...state,
        myAccountPage: {
          ...state.myAccountPage,
          orderTrack: payload,
        },
      };
    case 'IS_PAYMENT':
      return {
        ...state,
        isPaymentTab: payload,
      };
    case 'CANCEL_REASONS':
      return {
        ...state,
        myAccountPage: {
          ...state.myAccountPage,
          cancelReasons: payload,
        },
      };
    case 'ADD_GIFTCARD':
      const giftCards = [
        ...state.checkoutPage.giftCards,
        {
          number: payload.number,
          transactionId: payload.transactionId,
          amount: payload.amount,
          cardAmount: payload.cardAmount,
          pincode: payload.pincode,
          expiryDate: payload.expiryDate,
        },
      ];
      return {
        ...state,
        checkoutPage: {
          ...state.checkoutPage,
          giftCards,
        },
      };
    case 'REMOVE_GIFTCARD':
      return {
        ...state,
        checkoutPage: {
          ...state.checkoutPage,
          giftCards:
            payload === 'ALL' ? [] : state.checkoutPage.giftCards.filter((giftCard) => giftCard.number !== payload),
        },
      };
    case 'SET_CHECKOUT_ERROR':
      return {
        ...state,
        checkoutPage: {
          ...state.checkoutPage,
          checkoutError: payload,
        },
      };

    case 'DECA_SLIDE_MODAL_ACTION':
      return {
        ...state,
        decaSlideModalData: {
          ...state.decaSlideModalData,
          status: payload.status,
          modelID: payload?.modelID || null,
        },
      };

    case 'SET_CATEGORY_PAGE_DATA':
      return {
        ...state,
        categoryPage: {
          ...state.categoryPage,
          ...payload,
        },
      };
    case 'SET_CATEGORY_BANNERS':
      return {
        ...state,
        categoryPage: {
          ...state.categoryPage,
          categoryBanners: {
            ...payload,
          },
        },
      };
    case 'SET_SHOP_PAGE_DATA':
      return {
        ...state,
        shopPage: {
          ...state.shopPage,
          ...payload,
        },
      };
    case 'SET_BLOG_PAGE_DATA':
      return {
        ...state,
        blogPage: {
          ...state.blogPage,
          ...payload,
        },
      };
    case 'SET_AVAILABLE_INTENT_APPS':
      return {
        ...state,
        checkoutPage: {
          ...state.checkoutPage,
          availableIntentApps: payload,
        },
      };
    case 'PERSO_RECO_LOADER':
      return {
        ...state,
        persoRecoContent: {
          ...state.persoRecoContent,
          persoRecoLoader: payload,
        },
      };
    case 'GET_PERSO_RECO_DATA':
      return {
        ...state,
        persoRecoContent: {
          ...state.persoRecoContent,
          persoRecoData: payload.persoRecoData,
          persoRecoTitle: payload.persoRecoTitle,
        },
      };
    case 'GET_MULTI_PRODUCTS':
      return {
        ...state,
        productPage: {
          ...state.productPage,
          multiProductsList: payload,
        },
      };
    case 'SET_SELECTED_MULTI_PRODUCT_DATA':
      return {
        ...state,
        productPage: {
          ...state.productPage,
          selectedMultiProductData: payload,
        },
      };
    case 'UPDATE_SELECTED_ADDRESS_IDS':
      return {
        ...state,
        cartPage: {
          ...state.cartPage,
          cartItems: {
            ...state.cartPage?.cartItems,
            addresses: [
              {
                addressType: 'SHIPPING',
                id: payload.shippingAddress,
              },
              {
                addressType: 'BILLING',
                id: payload.billingAddress,
              },
            ],
          },
        },
      };
    case 'CRED_ELIGIBILITY_CHECK':
      return {
        ...state,
        checkoutPage: {
          ...state.checkoutPage,
          CREDEligibility: {
            status: payload.status,
            description: payload.description,
          },
        },
      };
    case 'STORE_DETAILS':
      return {
        ...state,
        storeDetailsPage: {
          storedetail: payload,
        },
      };
    case 'RECENT_SEARCH_SUGGESTIONS':
      return {
        ...state,
        showRecentSuggestions: payload,
      };
    case 'SET_MULTI_PRODUCT_BUTTON_CLICK':
      return {
        ...state,
        productPage: {
          ...state.productPage,
          multiProductButtonClick: payload,
        },
      };
    case 'BNPL_MODAL_TOGGLE':
      return {
        ...state,
        bnplModalToggle: !state.bnplModalToggle,
      };
    case 'UNMOUNT_LOGIN_POPUP':
      return {
        ...state,
        loginPayload: {
          isLoggedIn: false,
          mobile: false,
          email: false,
          access_token: false,
          refresh_token: false,
          verification_token: false,
          signinStep: 1,
          flag: 0,
          loader: false,
        },
      };
    case 'STORE_SEARCHBAR_KEYWORDS':
      return {
        ...state,
        searchBarKeys: {
          placeholder: payload.defaultSearchText,
          keywords: payload.searchBarKeywords,
        },
      };
    case 'VOICE_SEARCH_ERROR':
      return {
        ...state,
        voiceSearchError: payload,
      };
    case 'SET_LOGIN_PAYLOAD':
      return {
        ...state,
        isLoggedIn: payload,
      };

    case 'SET_RIBBON_BANNER':
      return {
        ...state,
        ribbonBanners: payload,
      };

    case 'SET_RIBBON_BANNER_IN_CATEGORY_PAGE':
      return {
        ...state,
        ribbonBannersInCategory: payload,
      };

    default:
      return state;
  }
};

export default reducer;
