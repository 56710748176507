const crypto = require('crypto');

const key = process.env.NEXT_PUBLIC_CRYPTO_KEY;
const salt = crypto.randomBytes(16);
const algorithm = 'aes-256-gcm';

export const encryptCipher = (value) => {
  if (!value) return null;
  let encryptedText = null;
  const string = JSON.stringify(value);

  try {
    const cipher = crypto.createCipheriv(algorithm, Buffer.from(key), salt);

    encryptedText = cipher.update(string);
    encryptedText = Buffer.concat([encryptedText, cipher.final()]);
    encryptedText = encodeURIComponent(
      `${salt.toString('base64')}${cipher.getAuthTag().toString('base64')}${encryptedText.toString('base64')}`,
    );
  } catch (error) {
    encryptedText = null;
  }

  return encryptedText;
};

export const decryptCipher = (string) => {
  if (!string) return null;
  let decryptedText = null;
  const decodedString = decodeURIComponent(string);

  try {
    const encryptedSalt = decodedString.substring(0, 24);
    const encryptedAuthTag = decodedString.substring(24, 48);
    const encryptedString = decodedString.substring(48);

    const cipher = crypto.createDecipheriv(algorithm, Buffer.from(key), Buffer.from(encryptedSalt, 'base64'));
    decryptedText = cipher.update(Buffer.from(encryptedString, 'base64'));
    cipher.setAuthTag(Buffer.from(encryptedAuthTag, 'base64'));
    decryptedText = Buffer.concat([decryptedText, cipher.final()]);
    decryptedText = decryptedText.toString();
  } catch (error) {
    decryptedText = null;
  }

  return decryptedText ? JSON.parse(decryptedText) : decryptedText;
};
