const getAspectRatio = (width, height) => {
  return (height / width) * 100;
};

const constants = {
  decaImage: {
    shopByCategory: {
      desktop: getAspectRatio(440, 483),
      mobile: getAspectRatio(61, 63),
    },
    mainBanner: {
      desktop: getAspectRatio(1920, 470),
      mobile: getAspectRatio(768, 502),
    },
    mainBannerV2: {
      desktop: getAspectRatio(1920, 470),
      mobile: getAspectRatio(768, 960),
    },
    storeMainBanner: {
      desktop: getAspectRatio(1920, 470),
      mobile: getAspectRatio(768, 502),
    },
    afsBlogBanner: getAspectRatio(603, 452),
    blogBanner: getAspectRatio(440, 242),
    fourBanner: { aspectratio: getAspectRatio(432, 467), desktop: 440, mobile: 198 },
    heroBanner: {
      desktop: getAspectRatio(1800, 627),
      mobile: getAspectRatio(768, 618),
    },
    heroBannerV2: {
      desktop: getAspectRatio(1600, 328),
      mobile: getAspectRatio(909, 375),
    },
    miniBanner: getAspectRatio(768, 828),
    multipleBigBanner: {
      bigImage: getAspectRatio(1024, 824),
      smallImage: getAspectRatio(1024, 258),
    },
    categoryBanners: {
      desktop: getAspectRatio(1600, 328),
      mobile: getAspectRatio(909, 375),
    },
    dualBannerCardOne: getAspectRatio(1024, 600),
    secondaryImageCard: getAspectRatio(1024, 600),
    productCollage: {
      bannerImage: getAspectRatio(1024, 497),
      mobile: getAspectRatio(768, 1494),
    },
    categoryPage: getAspectRatio(1920, 246),
    appLink: getAspectRatio(100, 34),
  },
  empCode: 'EMPDISC',
};

export default constants;
