const initialState = {
  activeProduct: {},
  productColors: [],
  isAddItemToCartLoading: false,
  isStockLoading: true,
  isAddToItemCartPopupVisisble: false,
  isNotifyMeLoading: false,
  isItemAddingToCart: false,
  addToCartProductId: null,
  addToCartFromNonGenericProductCard: false,
  showQuantitySelector: true,
  hideColorOptions: true,
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case 'AITC_PRODUCT_DETAILS_LOADER':
      return {
        ...state,
        isAddItemToCartLoading: payload,
      };

    case 'AITC_PRODUCT_DETAILS':
      return {
        ...state,
        activeProduct: payload.activeProduct,
        productColors: payload.productColors,
        isAddItemToCartLoading: false,
      };

    case 'AITC_CHNAGE_COLOR':
      return {
        ...state,
        activeProduct: payload.activeProduct,
      };

    case 'AITC_CHNAGE_COLOR_LOADER':
      return {
        ...state,
        isStockLoading: payload,
      };

    case 'AITC_STOCK_UPDATE':
      return {
        ...state,
        activeProduct: {
          ...state.activeProduct,
          articles: payload,
        },
        isStockLoading: false,
      };
    case 'AITC_SHOW_POPUP':
      return {
        ...state,
        isAddToItemCartPopupVisisble: payload,
      };
    case 'AITC_NOTIFY_ME_LOADER':
      return {
        ...state,
        isNotifyMeLoading: payload,
      };
    case 'AITC_ADD_TO_CART_LOADING':
      return {
        ...state,
        isItemAddingToCart: payload,
      };
    case 'AITC_ADD_TO_CART_PROUDUCTID':
      return {
        ...state,
        addToCartProductId: payload,
      };
    case 'AITC_ADD_TO_CART_FROM_NON_GENERIC_PRODUCT_CARD':
      return {
        ...state,
        addToCartFromNonGenericProductCard: payload,
      };
    case 'AITC_PRODUCT_POPUP_CONFIG':
      return {
        ...state,
        showQuantitySelector: payload?.showQuantitySelector ?? state?.showQuantitySelector,
        hideColorOptions: payload?.hideColorOptions ?? state?.hideColorOptions,
      };

    default:
      return state;
  }
};

export default reducer;
