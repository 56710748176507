const initialState = {
  activeCategory: null,
  currentStep: 0,
  activeCategoryTitle: null,
  navigationMenuOpen: false,
  activeSection: null,
  lastCategoryTitle: null,
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case 'SET_ACTIVE_CATEGORY':
      return {
        ...state,
        activeCategory: payload?.contents,
        lastCategoryTitle: payload?.title,
        activeCategoryTitle: payload?.title,
        activeSection: payload?.title,
        currentStep: ++state.currentStep,
      };

    case 'NAVIGATION_TOGGLE': {
      return {
        ...state,
        navigationMenuOpen: payload,
      };
    }

    case 'ACTIVE_NAV_SECTION': {
      return {
        ...state,
        activeSection: payload,
      };
    }

    default:
      return state;
  }
};

export default reducer;
