import fetchAppRedirectionURLs from '../Utils/fetchAppRedirectionURLs';

export default async function rewriteRoutes({ req, asPath }: any, contextDevice: string) {
  const isDesktop = contextDevice === 'desktop';
  const userAgent = req?.headers['user-agent'];

  switch (true) {
    case asPath.indexOf('/qr/invoice') !== -1:
      return asPath.replace('/qr/', '/p/');

    case asPath.indexOf('/myaccount') !== -1:
      return isDesktop ? '/account/orders-returns' : '/account';

    case asPath.indexOf('/app/') !== -1 || asPath === '/app':
      const { android, ios } = await fetchAppRedirectionURLs();

      if (/android/i.test(userAgent)) {
        return android;
      } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
        return ios;
      }

      return '/';

    default:
      return false;
  }
}
