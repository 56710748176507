import { getCookies } from '@/src/Utils/Cookies';

const timestamp = new Date().getTime();

export const getSignInPageUrl = (url) => {
  let redirection = '';

  const { dkt_loadtest } = getCookies();

  if (typeof window === 'object' && !url) {
    redirection = window.location.pathname.length > 1 ? `&redirection=${encodeURIComponent(window.location.href)}` : '';
  }

  if (url) {
    redirection = `&redirection=${encodeURIComponent(process.env.NEXT_PUBLIC_SITE_URL + '/' + url)}`;
  }
  if (dkt_loadtest) {
    return `${process.env.NEXT_PUBLIC_SITE_URL}/login?redirect=${window.location.href}`;
  }

  return `${process.env.NEXT_PUBLIC_SITE_URL}/api/signin?timestamp=${timestamp}${redirection}`;
};

export const redirectToSignInPage = (ctx, url) => {
  if (ctx?.res) {
    ctx.res.writeHead(301, { Location: getSignInPageUrl(url) });
    ctx.res.end();
    return true;
  }

  if (typeof window === 'object') {
    window.location = getSignInPageUrl(url);
    return true;
  }

  return false;
};
