import { jwtDecode } from 'jwt-decode';
import axios from 'axios';
import qs from 'qs';
import base64 from 'base-64';
import { createHash, randomBytes } from 'crypto';
import { decryptCipher, encryptCipher } from '../Utils/Crypto';
import { removeCookie, setCookie } from '../Utils/Cookies';
import { v4 as _uuidv4, validate as _uuidValidate, version as _uuidVersion } from 'uuid';
import { getQwikcilverAccessToken } from '../Utils/qwikcilver';

const isProd = process.env.NEXT_PUBLIC_ENVIRONMENT === 'PRODUCTION';

export const sendResponse = (res, response) => {
  // console.log('middleware helper-sendResponse', response.statusCode);
  if (isProd) {
    delete response.reason;
    delete response.apiError;
    delete response.requiredFields;
  }
  // console.log('middleware helper - sendResponse ', response);

  const ret = res.status(response?.statusCode || 500).json(response);
  // console.log('middleware helper - sendResponse ret', ret);
  return ret;
};

export const encrypt = (value) => {
  let encryptedValue;
  try {
    encryptedValue = encryptCipher(value);
  } catch (error) {
    encryptedValue = false;
  }
  return encryptedValue;
};

export const decrypt = (value) => {
  let decryptedValue;
  if (!value) return false;
  try {
    decryptedValue = decryptCipher(value);
  } catch (error) {
    decryptedValue = false;
  }
  return decryptedValue;
};

export const uuidGenerate = () => {
  return _uuidv4();
};

export const uuidValidate = (uuid) => {
  return _uuidValidate(uuid) && _uuidVersion(uuid) === 4;
};

export const setUserSessionToCookies = ({ req, res, sessionId, sessionVerifier, exp }) => {
  const sessionIdEncrypted = encrypt({ sessionId, sessionVerifier });
  setCookie('dkt_sessionId', sessionIdEncrypted, {
    req,
    res,
    httpOnly: true,
  });
  setCookie('dkt_isLoggedIn', true, { req, res });
  setCookie('dkt_exp', true, { req, res, expires: new Date((exp - 60) * 1000) }); // cookie expiry time is set 1 minute earlier
};

export const createUserSession = ({ userAgent, accessToken, refreshToken }) => {
  const sessionId = uuidGenerate();
  const { verifier: sessionVerifier, challenge: sessionChallenge } = generateChallenge();

  const sessionData = {
    accessToken,
    refreshToken,
    userAgent,
    sessionChallenge,
  };
  return { sessionId, sessionVerifier, sessionData };
};

export const getDecryptedSessionId = (encryptedSessionId) => {
  const decryptedSessionId = decrypt(encryptedSessionId) || {};
  let { sessionId, sessionVerifier } = decryptedSessionId;
  sessionId = uuidValidate(sessionId) && sessionId;
  return { sessionId, sessionVerifier };
};

export const setUserDataToCookies = ({
  req,
  res,
  memberId,
  cardNumber,
  gender,
  email,
  mobileNumber,
  firstName,
  lastName,
  consent,
  refreshToken,
}) => {
  const refreshTokenExp = jwtDecode(refreshToken)?.exp;
  const time = new Date().getTime();

  const refreshTokenExpiry = refreshTokenExp
    ? refreshTokenExp * 1000 - 1000 * 60 * 2
    : time + Number(process.env.REFRESH_TOKEN_EXPIRY_TIME);

  if (memberId)
    setCookie('dkt_memberId', encrypt(memberId), {
      req,
      res,
      expires: new Date(refreshTokenExpiry),
    });
  if (cardNumber)
    setCookie('dkt_loyaltyCard', encrypt(cardNumber), {
      req,
      res,
      expires: new Date(refreshTokenExpiry),
    });
  if (gender)
    setCookie('dkt_ug', encrypt(gender), {
      req,
      res,
      expires: new Date(refreshTokenExpiry),
    });
  if (email)
    setCookie('dkt_em', encrypt(email), {
      req,
      res,
      expires: new Date(refreshTokenExpiry),
    });
  if (mobileNumber)
    setCookie('dkt_mn', encrypt(mobileNumber), {
      req,
      res,
      expires: new Date(refreshTokenExpiry),
    });
  if (firstName)
    setCookie('dkt_ufn', encrypt(firstName), {
      req,
      res,
      expires: new Date(refreshTokenExpiry),
    });
  if (lastName)
    setCookie('dkt_uln', encrypt(lastName), {
      req,
      res,
      expires: new Date(refreshTokenExpiry),
    });
  if (consent)
    setCookie('dkt_consent', encrypt(consent), {
      req,
      res,
      expires: new Date(refreshTokenExpiry),
    });
};

export const removeTokensFromCookies = (req, res) => {
  removeCookie('dkt_sessionId', { req, res });
  removeCookie('dkt_isLoggedIn', { req, res });
  removeCookie('dkt_exp', { req, res });
  removeCookie('dkt_timestamp', { req, res });
  removeCookie('dkt_memberId', { req, res });
  removeCookie('dkt_loyaltyCard', { req, res });
  removeCookie('dkt_ug', { req, res });
  removeCookie('dkt_em', { req, res });
  removeCookie('dkt_mn', { req, res });
  removeCookie('dkt_ufn', { req, res });
  removeCookie('dkt_uln', { req, res });
  removeCookie('dkt_consent', { req, res });
  removeCookie('dkt_reviewToken', { req, res });
  removeCookie('dkt_fedIdToken', { req, res });
  removeCookie('dkt_cartId', { req, res });
  removeCookie('dkt_loadtest', { req, res });
  removeCookie('walletId', { req, res });
};

export const fetchNewTokens = async (_refreshToken) => {
  let response = {};

  try {
    await axios
      .post(
        `${process.env.DECACONNECT_GLOBAL_ENDPOINT}connect/oauth/token`,
        qs.stringify({
          grant_type: 'refresh_token',
          refresh_token: _refreshToken,
        }),
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: `Basic ${base64.encode(`${process.env.DECACONNECT_CLIENT_ID}:`)}`,
          },
        },
      )
      .then((res) => {
        const { access_token: accessToken, refresh_token: refreshToken } = res?.data || {};

        if (accessToken && refreshToken) {
          response = {
            status: true,
            accessToken,
            refreshToken,
          };
        }
      })
      .catch((error) => {
        response = {
          status: false,
          statusCode: error?.response?.status,
          error: String(error),
        };
      });
  } catch (error) {
    response = {
      status: false,
      statusCode: error?.response?.status,
      error: String(error),
    };
  }

  return response;
};
export const fetchUserData = async (accessToken) => {
  try {
    const { sub: memberId } = jwtDecode(accessToken);
    const identityResponse = await axios.get(
      `${process.env.DECACONNECT_GLOBAL_ENDPOINT}identity/v1/members/profile/me`,
      {
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': process.env.DECACONNECT_IDENTITY_X_API_KEY,
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );
    const consentResponse = await axios
      .get(
        `${process.env.DECACONNECT_GLOBAL_ENDPOINT}consent_management/external/v1/user?autocomplete=true&country_code=IN&tags=communication`,
        {
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': process.env.DECACONNECT_CONSENT_X_API_KEY,
            Authorization: `Bearer ${accessToken}`,
          },
        },
      )
      .then((res) => res)
      .catch(() => null);

    const { claims = {}, identifiers = [] } = identityResponse?.data || {};
    const { gender, email, phone_number: mobileNumber, given_name: firstName, family_name: lastName } = claims || {};
    const cardNumber = identifiers.find((item) => item.id === 'loyalty_card')?.value;

    const { channels = [], purposes = [] } = consentResponse?.data?.events?.[0]?.consents || {};

    const emailEnabled = channels?.find(
      (channel) => channel?.id === process.env.DECACONNECT_CONSENT_EMAIL_OPTOUT_CHANNEL_ID,
    )?.enabled;

    const sportEnabled = purposes?.find(
      (purpose) => purpose?.id === process.env.DECACONNECT_CONSENT_SPORT_OPTIN_PURPOSE_ID,
    )?.enabled;

    const consent = !!(emailEnabled && sportEnabled);

    return {
      status: true,
      data: {
        memberId,
        cardNumber,
        gender,
        email,
        mobileNumber,
        firstName,
        lastName,
        consent: String(consent),
      },
    };
  } catch (error) {
    return {
      status: false,
      error,
    };
  }
};

export const generateCodeChallenge = (codeVerifier) => {
  const hash = createHash('sha256').update(codeVerifier).digest('base64');
  return hash.replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_');
};

export const generateChallenge = () => {
  const generateCodeVerifier = (size) => {
    const mask = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789-._~';
    let result = '';
    const randomIndices = randomBytes(size);
    const byteLength = 256;
    const maskLength = Math.min(mask.length, byteLength);
    // the scaling factor breaks down the possible values of bytes (0x00-0xFF)
    // into the range of mask indices
    const scalingFactor = byteLength / maskLength;
    for (let i = 0; i < size; i++) {
      const randomIndex = Math.floor(randomIndices[i] / scalingFactor);
      result += mask[randomIndex];
    }

    return result;
  };

  const verifier = generateCodeVerifier(128);
  const challenge = generateCodeChallenge(verifier);
  return {
    verifier,
    challenge,
  };
};

export const apiResponses = (backendServiceName) => {
  const createReason = (reason) => {
    return `${backendServiceName || 'Backend Service'} - ${process.env.NEXT_PUBLIC_ENVIRONMENT}:: ${reason}`;
  };

  const filterApiResponse = (res) => {
    if (isProd) {
      delete res.reason;
      delete res.apiError;
      delete res.requiredFields;
      delete res.response;
      delete res.apiInfo;
    }

    return res;
  };

  return {
    success: ({ response, ...rest }) =>
      filterApiResponse({
        status: true,
        statusCode: 200,
        reason: createReason('API request fetched successfully.'),
        apiInfo: {
          backendApiURL: response?.config?.url,
          httpMethod: response?.config?.method,
          headers: { ...response?.config?.headers, 'x-api-key': 'hidden 😛' },
          body: JSON.parse(response?.config?.data || '{}'),
        },
        ...rest,
      }),
    failed: ({ error, ...rest }) =>
      filterApiResponse({
        status: false,
        statusCode: error?.response?.status || 500,
        apiError: error?.response?.data || error?.toString() || {},
        message: process.env.NEXT_PUBLIC_ERROR_MESSAGE,
        reason: createReason('Issue occurred while fetching backend api.'),
        apiInfo: {
          backendApiURL: error?.config?.url,
          httpMethod: error?.config?.method,
          headers: { ...error?.config?.headers, 'x-api-key': 'hidden 😛' },
          body: JSON.parse(error?.config?.data || '{}'),
        },
        ...rest,
      }),
    invalid: (res) =>
      filterApiResponse({
        status: false,
        statusCode: 422,
        message: process.env.NEXT_PUBLIC_ERROR_MESSAGE,
        reason: 'BFF API: Fields are invalid or Required fields are unavailable in the API request.',
        ...res,
      }),
    catch: ({ error, ...rest }) =>
      filterApiResponse({
        status: false,
        statusCode: 500,
        apiError: error?.toString(),
        message: process.env.NEXT_PUBLIC_ERROR_MESSAGE,
        reason: 'BFF API: Issue occurred while checking backend api.',
        ...rest,
      }),
  };
};

const fetchFedIdToken = async (req, res) => {
  let response = {};

  try {
    await axios
      .post(
        `${process.env.DECA_FEDID_ENDPOINT}${process.env.DECA_FEDID_TOKEN_URL}`,
        qs.stringify({ grant_type: 'client_credentials' }),
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: `Basic ${base64.encode(
              `${process.env.DECA_FEDID_CLIENT_ID}:${process.env.DECA_FEDID_SECRET}`,
            )}`,
          },
        },
      )
      .then((resp) => {
        response = {
          status: !!resp?.data?.access_token,
          token: resp?.data?.access_token,
        };
        if (resp?.data?.access_token) {
          setCookie('dkt_fedIdToken', encrypt(resp?.data?.access_token), {
            req,
            res,
          });
        }
      })
      .catch(() => {
        response = {
          status: false,
        };
      });
  } catch (error) {
    response = {
      status: false,
    };
  }

  return response;
};

export const apisWithFedIdRequests = async (req, res, ApiRequest) => {
  let fedIdToken = decrypt(req?.cookies?.dkt_fedIdToken);
  if (!fedIdToken) {
    const apiResponse = await fetchFedIdToken(req, res);

    if (!apiResponse?.status) {
      return sendResponse(res, {
        status: false,
        statusCode: 400,
        reason: 'Middleware Error:: Failed to fetch fedId token.',
        message: 'Your request could not be serviced. Please try again!!',
      });
    }

    fedIdToken = apiResponse.token;
  }

  let response = await ApiRequest(
    {
      ...req,
      cookies: {
        ...req.cookies,
        fedIdToken,
      },
    },
    res,
  );

  if (response?.statusCode === 401) {
    const apiResponse = await fetchFedIdToken(req, res);

    if (!apiResponse?.status) {
      return sendResponse(res, {
        status: false,
        statusCode: 400,
        reason: 'Middleware Error:: Failed to fetch fedId token.',
        message: 'Your request could not be serviced. Please try again!!',
      });
    }

    fedIdToken = apiResponse.token;
  }

  response = await ApiRequest(
    {
      ...req,
      cookies: {
        ...req.cookies,
        fedIdToken,
      },
    },
    res,
  );

  return sendResponse(res, { ...response, statusCode: 200 });
};

export const apisWithQwikcilverTokenRequests = async (req, res, ApiRequest) => {
  let qwikcilverToken = decrypt(req?.cookies?.qwikcilverToken);

  if (!qwikcilverToken) {
    const apiResponse = await getQwikcilverAccessToken(req, res);

    if (!apiResponse?.status) {
      return sendResponse(res, {
        status: false,
        statusCode: 400,
        reason: 'Middleware Error:: Failed to fetch qwikcilver token.',
        message: 'Your request could not be serviced. Please try again!!',
      });
    }

    qwikcilverToken = apiResponse?.token || null;
  }

  let response = await ApiRequest(
    {
      ...req,
      cookies: {
        ...req.cookies,
        qwikcilverToken,
      },
    },
    res,
  );

  if (response?.statusCode === 401) {
    const apiResponse = await getQwikcilverAccessToken(req, res);

    if (!apiResponse?.status) {
      return sendResponse(res, {
        status: false,
        statusCode: 400,
        reason: 'Middleware Error:: Failed to fetch qwikcilver token.',
        message: 'Your request could not be serviced. Please try again!!',
      });
    }

    qwikcilverToken = apiResponse?.token || null;
  }

  response = await ApiRequest(
    {
      ...req,
      cookies: {
        ...req.cookies,
        qwikcilverToken,
      },
    },
    res,
  );

  return sendResponse(res, { ...response, statusCode: 200 });
};
