import React, { createContext } from 'react';

export const deviceTypes = {
  desktop: 'desktop',
  tablet: 'tablet',
  mobile: 'mobile',
};

const DeviceContext = createContext();

const DeviceContextProvider = ({ children, contextDevice }) => {
  return <DeviceContext.Provider value={{ contextDevice }}>{children}</DeviceContext.Provider>;
};

const withDeviceContext = (Component) => (props) => (
  <DeviceContext.Consumer>
    {(deviceContextProps) => <Component {...{ ...props, ...deviceContextProps }} />}
  </DeviceContext.Consumer>
);

export { DeviceContext, DeviceContextProvider, withDeviceContext };

export default DeviceContext;
