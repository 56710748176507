const initialState = {
  dyData: [],
  dyCategoryData: [],
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case 'GET_DY_DATA':
      return {
        ...state,
        dyData: payload,
      };
    case 'GET_CATEGORY_DY_DATA':
      return {
        ...state,
        dyCategoryData: payload,
      };
    default:
      return state;
  }
};

export default reducer;
