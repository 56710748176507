import axios from 'axios';
import { getHost } from '../Utils';
import { getCookie, getCookies, setCookie } from '../Utils/Cookies';
import { encrypt } from './helpers';
import { secureApiRoutes, partiallySecureApiRoutes } from './apiRoutes';

const fetchApi = async (ctx = null, url, options = {}) => {
  const {
    method = 'POST',
    headers = {},
    data = {},
    timeout = 0,
    onSuccess = false,
    onFail = false,
    retriedCount = 0,
    retry = 0,
    retryConditionally = false,
    cookies,
    signal,
  } = options;

  let apiResponse = {};

  const message = 'We are sorry, Your request could not be serviced. Please try again!!';
  try {
    const timestamp = new Date().getTime();
    const xHitFrom = encrypt({
      baseurl: process.env.NEXT_PUBLIC_SITE_URL,
      timestamp,
    });
    const exp = ctx ? getCookie('dkt_exp', ctx) : getCookie('dkt_exp');
    const isLoggedIn = ctx ? getCookie('dkt_isLoggedIn', ctx) : getCookie('dkt_isLoggedIn');
    const timestampCookie = ctx ? getCookie('dkt_timestamp', ctx) : getCookie('dkt_timestamp');
    if (
      isLoggedIn &&
      (secureApiRoutes.includes(url) || partiallySecureApiRoutes.includes(url)) &&
      !exp &&
      !timestampCookie
    ) {
      if (ctx) {
        setCookie('dkt_timestamp', timestamp, ctx);
      } else {
        setCookie('dkt_timestamp', timestamp);
      }
    }

    await axios({
      url: `${getHost(ctx)}${url}`,
      method,
      headers: ctx
        ? {
            ...headers,
            'X-Hit-From': xHitFrom,
            cookies: ctx ? JSON.stringify(getCookies(ctx)) : '',
            'user-agent': ctx?.req?.headers?.['user-agent'],
          }
        : {
            ...headers,
            'X-Hit-From': xHitFrom,
          },
      timeout,
      data,
      ...(signal ? { signal } : {}),
    })
      .then((response) => {
        const setCookies = response?.headers?.['set-cookie'];
        if (ctx?.res && setCookies) {
          ctx.res.setHeader('Set-Cookie', setCookies);
        }

        if (onSuccess) {
          const formattedResponse = onSuccess(response?.data);
          apiResponse = formattedResponse || response?.data;
        } else {
          apiResponse = response?.data;
        }

        if (response?.data?.fetchUserData) {
          if (ctx) {
            setCookie('dkt_fetch_user_details', true, ctx);

            return false;
          }

          fetchApi(null, `/api/dktlogin/get-user-details`);
        }
      })
      .catch(async (error) => {
        if (error?.response?.data?.fetchUserData) {
          if (ctx) {
            setCookie('dkt_fetch_user_details', true, ctx);

            return false;
          }

          fetchApi(null, `/api/dktlogin/get-user-details`);
        }
        const setCookies = error?.response?.headers?.['set-cookie'];
        if (ctx?.res && setCookies) {
          ctx.res.setHeader('Set-Cookie', setCookies);
        }

        if (onFail) {
          const formattedErrorResponse = onFail(error?.response);

          if (formattedErrorResponse !== undefined) {
            apiResponse = formattedErrorResponse;
            return false;
          }
        }

        const statusCode = error?.response?.data?.data?.statusCode || error?.response?.status;

        if (error?.message === 'Network Error') {
          apiResponse = {
            status: false,
            message: 'No internet connection make sure wifi or cellular data is turned on, then try again.',
          };
          return false;
        }

        if (statusCode === 401) {
          // server side redirection is possible from child components, So passing the 401 response.
          if (typeof window === 'object' && window.location.pathname !== '/payment') {
            window.location = '/api/logout';
          }

          apiResponse = {
            status: false,
            statusCode: 401,
          };

          return false;
        }

        if (statusCode >= 500) {
          apiResponse = {
            status: false,
            message,
            statusCode,
            ...error?.response?.data,
          };
          return false;
        }

        apiResponse = {
          ...error?.response?.data,
          statusCode: statusCode || error?.code,
          status: false,
          message: error?.response?.data?.message || error?.message,
        };
        return false;
      });
  } catch (error) {
    apiResponse = {
      status: false,
      message,
    };
  }

  if (retry && retryConditionally && retry > retriedCount) {
    if (!retryConditionally(apiResponse)) {
      return apiResponse;
    }

    apiResponse = await fetchApi(ctx, url, {
      ...options,
      retriedCount: retriedCount + 1,
    });
  }
  return {
    ...apiResponse,
    retried: retry > 0,
    retry,
  };
};

export default fetchApi;
