export const floorTypes = {
  appFloor: 'appFloor',
  blogBanner: 'blogBanner',
  categoryCarousel: 'categoryCarousel',
  categoryDescription: 'categoryDescription',
  dualBannerCard: 'twoBannerCardFloor',
  fourBanner: 'fourBanner',
  headingMainTitle: 'headingMainTitle',
  heroBanner: 'heroBanner',
  list: 'list',
  mainBanner: 'mainBanner',
  miniBanner: 'miniBanner4',
  multipleBanner: 'multipleBanner',
  persoReco: 'persoRecoFloor',
  productCarousel: 'productCarousel',
  productCollage: 'productCollage',
  productRecall: 'productRecall',
  productScroll: 'productScroll',
  promiseSection: 'promiseSection',
  shopByCategory: 'shopByCategory',
  shopByCategoryScroll: 'shopByCategoryScroll',
  shopByCategoryScrollRect: 'shopByCategoryScrollv2',
  categoryBanners: 'categoryBannerfloor',
  dualCardText: 'dualCardText',
  quickGuideFloor: 'quickGuideFloor',
  promiseV2: 'promiseV2',
  returnPolicyFaqs: 'returnPolicyFaqs',
  policyFaqs: 'policyFaqs',
  table: 'table',
  categoryBanners: 'categoryBannerfloor',
  content: 'content',
};

export const renameItems = (mainBannerCards) => {
  return mainBannerCards?.map((banner) => ({
    id: banner?.sys?.id || null,
    name: banner?.fields?.name || null,
    url: `${banner?.fields?.imageHyperLink}${banner?.fields?.hyperLinkTracking}` || null,
    hyperLinkTracking: banner?.fields?.hyperLinkTracking || null,
    openInNewTab: banner?.fields?.targetUrl || null,
    desktopImage: banner?.fields?.imageMediaUrlDesktop || null,
    tabletImage: banner?.fields?.imageMediaUrlDesktop || null,
    mobileImage: banner?.fields?.imageMediaUrlMobile || null,
    imageAltText: banner?.fields?.imageTags || null,
    ctaText: banner?.fields?.ctaText || null,
    bigTitle: banner?.fields?.bigTitle || null,
    smallTitle: banner?.fields?.smallTitle || null,
    price: banner?.fields?.price || null,
    priceText: banner?.fields?.priceText || null,
    pageType: banner?.fields?.pageTypeApp || null,
    text: banner?.fields?.text || null,
    floorType: banner?.fields?.floorType || null,
    gradientOpacity: banner?.fields?.gradientOpacity || null,
  }));
};

export const mainBanner = (floor) => {
  const data = {
    banners: renameItems(floor?.fields?.mainBannerCards),
  };

  return {
    type: floor?.sys?.contentType?.sys?.id || null,
    displayType: floor?.fields?.display || null,
    excludeShopInShop: floor?.fields?.excludeShopInShop || null,
    data,
    version: floor?.fields?.version || null,
  };
};

export const content = (floor) => {
  const data = {
    selectorName: floor?.fields?.key || null,
    floorType: floor?.fields?.dyFloorType || null,
  };
  return {
    type: floor?.sys?.contentType?.sys?.id || null,
    displayType: floor?.fields?.display || null,
    title: floor?.fields?.title || null,
    contentType: floor?.fields?.contentType || null,
    data,
  };
};

export const blogBanner = (floor) => {
  const data = {
    id: floor?.sys?.id || null,
    name: floor?.fields?.name || null,
    floorHeading: floor?.fields?.floorHeading || null,
    blogBannerCards: floor?.fields?.blogBannerCards?.map((blog) => ({
      youtubePopUp: blog?.fields?.youtubePopUp || null,
      imageHyperLink: blog?.fields?.imageHyperLink || null,
      targetUrl: blog?.fields?.targetUrl || null,
      hyperLinkTracking: blog?.fields?.hyperLinkTracking || null,
      imageMediaUrl: blog?.fields?.imageMediaUrl || null,
      imageTags: blog?.fields?.imageTags || null,
      bigTitle: blog?.fields?.bigTitle || null,
      smallTitle: blog?.fields?.smallTitle || null,
      ctaText: blog?.fields?.ctaText || null,
    })),
    floorCtaText: floor?.fields?.floorCtaText || null,
    floorHyperLinkTracking: floor?.fields?.floorHyperLinkTracking || null,
    floorHyperLink: floor?.fields?.floorHyperLink || null,
    floorImage: floor?.fields?.floorImage || null,
  };

  return {
    type: floor?.sys?.contentType?.sys?.id || null,
    displayType: floor?.fields?.display || null,
    excludeShopInShop: floor?.fields?.excludeShopInShop || null,
    data,
  };
};

export const categoryCarousel = (floor) => {
  let data = {};

  if (floor?.fields?.categoryCarouselImageCards) {
    data = {
      links: floor?.fields?.categoryCarouselImageCards?.map((link) => ({
        openInNewTab: link?.fields?.targetUrl || null,
        text: link?.fields?.bigTitle || null,
        href: link?.fields?.imageHyperLink || null,
      })),
    };
  }

  return {
    type: floorTypes?.categoryCarousel || null,
    displayType: floor?.fields?.display || null,
    data,
    excludeShopInShop: floor?.fields?.excludeShopInShop || null,
  };
};

export const fourBanner = (floor) => {
  const data = {
    heading: floor?.fields?.floorHeading,
    banners: floor?.fields?.fourBannerCards?.map((banner) => ({
      id: banner?.sys?.id || null,
      name: banner?.fields?.name || null,
      youtubePopUp: banner?.fields?.youtubePopUp || false,
      hyperlink: `${banner?.fields?.imageHyperLink}${banner?.fields?.hyperLinkTracking}` || null,
      hyperlinkTracking: banner?.fields?.hyperLinkTracking || null,
      openInNewTab: banner?.fields?.targetUrl || null,
      image: banner?.fields?.imageMediaUrl || null,
      imageAltText: banner?.fields?.imageTags || null,
      bigTitle: banner?.fields?.bigTitle || null,
      smallTitle: banner?.fields?.smallTitle || null,
      price: banner?.fields?.price || null,
      priceText: banner?.fields?.priceText || null,
      ctaText: banner?.fields?.ctaText || null,
      pageType: banner?.fields?.pageTypeApp || null,
    })),
  };

  return {
    type: floor?.sys?.contentType?.sys?.id || null,
    displayType: floor?.fields?.display || null,
    excludeShopInShop: floor?.fields?.excludeShopInShop || null,
    data,
  };
};

export const dualBannerCard = (floor) => {
  const data = {
    heading: floor?.fields?.floorHeading || null,
    banners: floor?.fields?.dualBannerCardOne?.map((banner) => ({
      id: banner?.sys?.id || null,
      name: banner?.fields?.name || null,
      youtubePopUp: banner?.fields?.youtubePopUp || false,
      hyperlink: `${banner?.fields?.imageHyperLink}${banner?.fields?.hyperLinkTracking}` || null,
      hyperlinkTracking: banner?.fields?.hyperLinkTracking || null,
      openInNewTab: banner?.fields?.targetUrl || null,
      image: banner?.fields?.imageMediaUrl || null,
      imageAltText: banner?.fields?.imageTags || null,
      bigTitle: banner?.fields?.bigTitle || null,
      smallTitle: banner?.fields?.smallTitle || null,
      price: banner?.fields?.price || null,
      priceText: banner?.fields?.priceText || null,
      ctaText: banner?.fields?.ctaText || null,
      pageType: banner?.fields?.pageTypeApp || null,
    })),
  };

  return {
    type: floor?.sys?.contentType?.sys?.id || null,
    displayType: floor?.fields?.display || null,
    excludeShopInShop: floor?.fields?.excludeShopInShop || null,
    data,
  };
};

export const heroBanner = (floor) => {
  const data = {
    id: floor?.fields?.heroBannerImageCard?.sys?.id || null,
    name: floor?.fields?.heroBannerImageCard?.fields?.name || floor?.data?.name,
    countDown: floor?.fields?.heroBannerImageCard?.fields?.countDown || null,
    countDownText: floor?.fields?.heroBannerImageCard?.fields?.countDownText || null,
    youtubePopUp: floor?.fields?.heroBannerImageCard?.fields?.youtubePopUp || null,
    imageHyperlink: floor?.fields?.heroBannerImageCard?.fields?.imageHyperLink || null,
    hyperlink: floor?.fields?.heroBannerImageCard?.fields?.imageHyperLink
      ? `${floor?.fields?.heroBannerImageCard?.fields?.imageHyperLink}${floor?.fields?.heroBannerImageCard?.fields?.hyperLinkTracking}`
      : `${floor?.data?.imageHyperlink}${floor?.data?.hyperlinkTracking}`,
    hyperlinkTracking: floor?.fields?.heroBannerImageCard?.fields?.hyperLinkTracking || null,
    imageDesktop: floor?.fields?.heroBannerImageCard?.fields?.imageMediaUrlDesktop || floor?.data?.imageDesktop,
    imageMobile: floor?.fields?.heroBannerImageCard?.fields?.imageMediaUrlMobile || floor?.data?.imageMobile,
    openInNewTab: floor?.fields?.heroBannerImageCard?.fields?.targetUrl || null,
    pageTypeApp: floor?.fields?.heroBannerImageCard?.fields?.pageTypeApp || null,
  };

  return {
    type: floor?.sys?.contentType?.sys?.id || null,
    displayType: floor?.fields?.display || null,
    excludeShopInShop: floor?.fields?.excludeShopInShop || null,
    data,
    heading: floor?.fields?.floorHeading || null,
    version: floor?.fields?.version || null,
  };
};

export const list = (floor) => {
  const data = {
    categories: floor?.fields?.listing
      ? floor?.fields?.listing?.map((category) => ({
          id: category?.sys?.id || null,
          image: category?.fields?.imageMediaUrl || null,
          imageAltText: category?.fields?.imageTags || null,
          name: category?.fields?.name || null,
          subCategories: category?.fields?.categoryListing
            ? category?.fields?.categoryListing?.map((subCategory) => ({
                id: subCategory?.sys?.id || null,
                hyperlink: subCategory?.fields?.textHyperLink || null,
                text: subCategory?.fields?.text || null,
                name: subCategory?.fields?.name || null,
                pageType: subCategory?.fields?.pageTypeApp || null,
              }))
            : [],
        }))
      : [],
  };

  return {
    type: floor?.sys?.contentType?.sys?.id || null,
    displayType: floor?.fields?.display || null,
    excludeShopInShop: floor?.fields?.excludeShopInShop || null,
    data,
  };
};

export const miniBanner = (floor) => {
  const data = {
    banners: floor?.fields?.miniBannerCards?.map((banner) => ({
      id: banner?.sys?.id || null,
      name: banner?.fields?.name || null,
      youtubePopUp: banner?.fields?.youtubePopUp || null,
      videoLink: banner?.fields?.imageHyperLink || null,
      image: banner?.fields?.imageMediaUrl || null,
      bigTitle: banner?.fields?.bigTitle || null,
      smallTitle: banner?.fields?.smallTitle || null,
      ctaText: banner?.fields?.ctaText || null,
      hyperlink: `${banner?.fields?.imageHyperLink}${banner?.fields?.hyperLinkTracking}` || null,
      hyperlinkTracking: banner?.fields?.hyperLinkTracking || null,
      openInNewTab: banner?.fields?.targetUrl || null,
      imageAltText: banner?.fields?.imageTags || null,
      pageType: banner?.fields?.pageTypeApp || null,
    })),
  };

  return {
    type: floor?.sys?.contentType?.sys?.id || null,
    displayType: floor?.fields?.display || null,
    excludeShopInShop: floor?.fields?.excludeShopInShop || null,
    data,
    floorHeading: floor?.fields?.floorHeading || null,
  };
};

export const multipleBanner = (floor) => {
  const data = {
    floorHeading: floor.fields.floorHeading,
    mainMultipleBannerCard: {
      id: floor?.fields?.mainMultipleBannerCard?.sys?.id || null,
      name: floor?.fields?.mainMultipleBannerCard?.fields?.name || null,
      imageHyperLink: floor?.fields?.mainMultipleBannerCard?.fields?.imageHyperLink || null,
      imageMediaUrl: floor?.fields?.mainMultipleBannerCard?.fields?.imageMediaUrl || null,
      smallTitle: floor?.fields?.mainMultipleBannerCard?.fields?.smallTitle || null,
      bigTitle: floor?.fields?.mainMultipleBannerCard?.fields?.bigTitle || null,
      ctaText: floor?.fields?.mainMultipleBannerCard?.fields?.ctaText || null,
      hyperLinkTracking: floor?.fields?.mainMultipleBannerCard?.fields?.hyperLinkTracking || null,
      youtubePopUp: floor?.fields?.mainMultipleBannerCard?.fields?.youtubePopUp || null,
      targetUrl: floor?.fields?.mainMultipleBannerCard?.fields?.targetUrl || null,
    },
    subMultipleBannerCards: floor?.fields?.subMultipleBannerCards?.map((banner) => ({
      id: banner?.sys?.id,
      name: banner?.fields?.name,
      youtubePopUp: banner?.fields?.youtubePopUp,
      imageMediaUrl: banner?.fields?.imageMediaUrl,
      bigTitle: banner?.fields?.bigTitle,
      smallTitle: banner?.fields?.smallTitle,
      imageHyperLink: banner?.fields?.imageHyperLink,
      hyperLinkTracking: banner?.fields?.hyperLinkTracking,
      targetUrl: banner?.fields?.targetUrl,
      imageTags: banner?.fields?.imageTags,
    })),
  };

  return {
    type: floor?.sys?.contentType?.sys?.id,
    displayType: floor?.fields?.display,
    excludeShopInShop: floor?.fields?.excludeShopInShop,
    data,
  };
};

export const persoReco = (floor) => {
  const data = {
    floorType: floor?.fields?.floorType || null,
    smallTitle: floor?.fields?.smallTitle || null,
    bigTitle: floor?.fields?.bigTitle || null,
    floorHeading: floor?.fields?.floorHeading || null,
    ...addSysIdForTest(floor),
  };

  return {
    type: floor?.sys?.contentType?.sys?.id || null,
    displayType: floor?.fields?.display || null,
    excludeShopInShop: floor?.fields?.excludeShopInShop || null,
    data,
  };
};

export const productCarousel = (floor) => {
  const data = {
    smallTitle: floor?.fields?.smallTitle || null,
    bigTitle: floor?.fields?.bigTitle || null,
    floorHeading: floor?.fields?.floorHeading || null,
    productCarouselCards: floor?.fields?.productCarouselCards?.map((product) => ({
      dsmModelCode: product?.fields?.dsmModelCode?.map((model) => ({
        dsmCode: model?.fields?.dsmCode || null,
        ratings: model?.fields?.ratings || null,
        modelcode: model?.fields?.modelcode || null,
        imageMediaUrl: model?.fields?.imageMediaUrl || null,
        imageMediaThumbnailUrl: model?.fields?.imageMediaThumbnailUrl || null,
        productName: model?.fields?.productName || null,
        sticker: model?.fields?.sticker || null,
        stickerImage: model?.fields?.stickerImage || null,
        sellingPrice: model?.fields?.sellingPrice || null,
        originalPrice: model?.fields?.originalPrice || null,
        catSlug: model?.fields?.catSlug || null,
        productSlug: model?.fields?.productSlug || null,
        priceLabel: model?.fields?.priceLabel || null,
        discountPercentage: model?.fields?.discountPercentage || null,
        colourOptions: model?.fields?.colourOptions || null,
        brand: model?.fields?.brand || null,
        familyId: model?.fields?.familyId || null,
        lifeCycle: model?.fields?.lifeCycle || null,
        nature: model?.fields?.nature || null,
        sport: model?.fields?.sport || null,
        sportPractice: model?.fields?.sportPractice || null,
        sportGroup: model?.fields?.sportGroup || null,
        superModel: model?.fields?.superModel || null,
        articleId: model?.fields?.articleId || null,
        variant: model?.fields?.variant || null,
        offers: model.fields?.promotion ? model.fields?.promotion.length : 0,
        showSlash: model?.fields?.showSlash,
      })),
    })),
    ...addSysIdForTest(floor),
  };

  return {
    type: floor?.sys?.contentType?.sys?.id || null,
    displayType: floor?.fields?.display || null,
    excludeShopInShop: floor?.fields?.excludeShopInShop || null,
    data,
  };
};

export const productCollage = (floor) => {
  const data = {
    productCollageHeader: {
      id: floor?.fields?.productCollageHeader?.id || null,
      name: floor?.fields?.productCollageHeader?.fields?.name || null,
      imageHyperLink: floor?.fields?.productCollageHeader?.fields?.imageHyperLink || null,
      hyperLinkTracking: floor?.fields?.productCollageHeader?.fields?.hyperLinkTracking || null,
      bigTitle: floor?.fields?.productCollageHeader?.fields?.bigTitle || null,
      smallTitle: floor?.fields?.productCollageHeader?.fields?.smallTitle || null,
      imageMediaUrlDesktop: floor?.fields?.productCollageHeader?.fields?.imageMediaUrlDesktop || null,
      imageMediaUrlMobile: floor?.fields?.productCollageHeader?.fields?.imageMediaUrlMobile || null,
      pageType: floor?.fields?.productCollageFooter?.fields?.pageTypeApp || null,
    },
    productCollageFooter: {
      id: floor?.fields?.productCollageFooter?.sys?.id || null,
      name: floor?.fields?.productCollageFooter?.fields?.name || null,
      imageHyperLink: floor?.fields?.productCollageFooter?.fields?.imageHyperLink || null,
      imageMediaUrlDesktop: floor?.fields?.productCollageFooter?.fields?.imageMediaUrlDesktop || null,
      imageMediaUrlMobile: floor?.fields?.productCollageFooter?.fields?.imageMediaUrlMobile || null,
      ctaText: floor?.fields?.productCollageFooter?.fields?.ctaText || null,
      pageType: floor?.fields?.productCollageFooter?.fields?.pageTypeApp || null,
    },
    heading: floor?.fields?.floorHeading || null,
    products: floor?.fields?.productCollageProductCards?.map((product) => ({
      models: product?.fields?.dsmModelCode?.map((dsm) => ({
        brand: dsm?.fields?.brand || null,
        familyId: dsm?.fields?.familyId || null,
        lifeCycle: dsm?.fields?.lifeCycle || null,
        nature: dsm?.fields?.nature || null,
        sport: dsm?.fields?.sport || null,
        sportPractice: dsm?.fields?.sportPractice || null,
        sportGroup: dsm?.fields?.sportGroup || null,
        superModel: dsm?.fields?.superModel || null,
        articleId: dsm?.fields?.articleId || null,
        variant: dsm?.fields?.variant || null,
        dsmCode: dsm?.fields?.dsmCode || null,
        modelcode: dsm?.fields?.modelcode || null,
        imageMediaUrl: dsm?.fields?.imageMediaUrl || null,
        productName: dsm?.fields?.productName || null,
        sticker: dsm?.fields?.sticker || null,
        stickerImage: dsm?.fields?.stickerImage || null,
        ratings: dsm?.fields?.ratings || 0,
        reviewCount: dsm?.fields?.reviewCount,
        sellingPrice: dsm?.fields?.sellingPrice,
        originalPrice: dsm?.fields?.originalPrice,
        catSlug: dsm?.fields?.catSlug,
        productSlug: dsm?.fields?.productSlug,
        priceLabel: dsm?.fields?.priceLabel,
        discountPercentage: dsm?.fields?.discountPercentage,
        colourOptions: dsm?.fields?.colourOptions,
        imageMediaThumbnailUrl: dsm?.fields?.imageMediaThumbnailUrl,
        offers: dsm?.fields?.promotion ? dsm.fields?.promotion.length : 0,
        showSlash: dsm?.fields?.showSlash,
      })),
    })),
    ...addSysIdForTest(floor),
  };

  return {
    type: floor?.sys?.contentType?.sys?.id || null,
    displayType: floor?.fields?.display || null,
    excludeShopInShop: floor?.fields?.excludeShopInShop || null,
    data,
  };
};

export const productScroll = (floor) => {
  const data = {
    smallTitle: floor?.fields?.smallTitle,
    bigTitle: floor?.fields?.bigTitle,
    floorHeading: floor?.fields?.floorHeading,
    productCarouselCards: floor?.fields?.productCarouselCards?.map((product) => ({
      dsmCode: product?.fields?.dsmCode,
      dsmModelCode: product?.fields?.dsmModelCode?.map((model) => ({
        ratings: model?.fields?.ratings,
        reviewCount: model?.fields?.reviewCount,
        modelcode: model?.fields?.modelcode,
        imageMediaUrl: model?.fields?.imageMediaUrl,
        productName: model?.fields?.productName,
        sticker: model?.fields?.sticker,
        stickerImage: model?.fields?.stickerImage,
        sellingPrice: model?.fields?.sellingPrice,
        originalPrice: model?.fields?.originalPrice,
        catSlug: model?.fields?.catSlug,
        productSlug: model?.fields?.productSlug,
        priceLabel: model?.fields?.priceLabel,
        discountPercentage: model?.fields?.discountPercentage,
        colourOptions: model?.fields?.colourOptions,
        imageMediaThumbnailUrl: model?.fields?.imageMediaThumbnailUrl,
        brand: model?.fields?.brand,
        familyId: model?.fields?.familyId,
        lifeCycle: model?.fields?.lifeCycle,
        nature: model?.fields?.nature,
        sport: model?.fields?.sport,
        sportPractice: model?.fields?.sportPractice,
        sportGroup: model?.fields?.sportGroup,
        superModel: model?.fields?.superModel,
        articleId: model?.fields?.articleId,
        variant: model?.fields?.variant,
        offers: model?.fields?.promotion ? model?.fields?.promotion?.length : 0,
        showSlash: model?.fields?.showSlash,
      })),
    })),
    ...addSysIdForTest(floor),
  };

  return {
    type: floor?.sys?.contentType?.sys?.id || null,
    displayType: floor?.fields?.display || null,
    excludeShopInShop: floor?.fields?.excludeShopInShop || null,
    data,
  };
};

export const promiseSection = (floor) => {
  let promiseCards = [];

  if (floor?.fields?.ourPromiseCards) {
    promiseCards = floor?.fields?.ourPromiseCards
      .filter((card) => card?.fields?.text)
      .map((card) => ({
        text: card?.fields?.text || null,
        href: card?.fields?.imageHyperLink || null,
        openInNewTab: card?.fields?.targetUrl || null,
        image: card?.fields?.imageMediaUrl || null,
      }));
  }

  return {
    type: floorTypes?.promiseSection || null,
    displayType: floor?.fields?.display || null,
    excludeShopInShop: floor?.fields?.excludeShopInShop || null,
    cards: promiseCards || null,
    ...addSysIdForTest(floor),
  };
};

export const headingMainTitle = (floor) => {
  const data = {
    name: floor?.fields?.name || null,
    mainTitle: floor?.fields?.mainTitle || null,
    pageTab: floor?.fields?.pageTab,
  };
  return {
    type: floorTypes?.headingMainTitle || null,
    displayType: floor?.fields?.display || null,
    // excludeShopInShop: floor?.fields?.excludeShopInShop,
    // cards: promiseCards
    name: floor?.fields?.name || null,
    mainTitle: floor?.fields?.mainTitle || null,
    data,
  };
};

export const categoryDescription = (floor) => {
  return {
    type: floor?.sys?.contentType?.sys?.id || null,
    displayType: floor?.fields?.display || null,
    data: {
      description: [
        {
          fields: {
            categoryDescriptionTitle: floor.fields.categoryDescriptionTitle || null,
            categoryDescriptionText: floor.fields.categoryDescriptionText || null,
          },
        },
      ],
      changeFont: floor?.fields?.changeFont,
    },
  };
};

const getMegaMenuCategories = (categories) => {
  return categories.map((category) => {
    let result = {
      title: category.fields.title || category.fields.text || null,
      image: category.fields.title ? category.fields.imageMediaUrl : null,
      hyperlink: category.fields.textHyperLink ? category.fields.textHyperLink : null,
      pageType: category?.fields?.pageTypeApp || '',
    };

    if (category.fields.hasOwnProperty('sportList')) {
      result.categories = getMegaMenuCategories(category.fields.sportList);
    } else if (category.fields.hasOwnProperty('categoryListing')) {
      result.categories = getMegaMenuCategories(category.fields.categoryListing);
    }

    if (category.fields.hasOwnProperty('imageList')) {
      result.offer = {
        ctaText: category.fields.imageList.fields.ctaText || null,
        hyperlink: category.fields.imageList.fields.imageHyperLink || null,
        imageMobile: category.fields.imageList.fields.imageMediaUrlMobile || null,
        imageDesktop: category.fields.imageList.fields.imageMediaUrlDesktop || null,
        pageType: category?.fields?.pageTypeApp || '',
      };
    }

    return result;
  });
};

export const megaMenu = (data) => {
  let result = {};

  if (data?.items && data.items?.[0]?.fields) {
    result.title = data.items?.[0]?.fields.title || null;
    result.blogs = data.items?.[0]?.fields.megaMenuBlogs || null;
    result.categories = getMegaMenuCategories(data.items?.[0]?.fields.megaMenuCategorisation) || null;
  }

  return result;
};

const getNewMegaMenuCategories = (contents) => {
  return contents.map((category) => {
    let result = {
      title: category?.fields?.title || null,
      image: category?.fields?.imageUrl || null,
      hyperlink: category?.fields?.hyperlinkUrl || '',
      contentType: category?.fields?.contentType || '',
      ctaText: category?.fields?.ctaText || '',
    };

    if (category?.fields?.hasOwnProperty('contents')) {
      result.contents = getNewMegaMenuCategories(category?.fields?.contents);
    }

    return result;
  });
};

export const newMegaMenu = (data) => {
  let result = {};

  if (data?.items && data?.items[0]?.fields) {
    result.title = data?.items[0]?.fields?.title || null;
    result.image = data?.items[0]?.fields?.imageUrl || null;
    result.contents = getNewMegaMenuCategories(data?.items[0]?.fields?.contents) || null;
  }

  return result;
};

function addSysIdForTest(floor) {
  if (process.env.NODE_ENV === 'test') {
    return { sysId: floor.sys.id };
  }
  return {};
}

export const shopByCategories = (args) => {
  const { fields } = args;
  return {
    type: args?.sys?.contentType?.sys?.id || '',
    displayType: fields?.display || '',
    excludeShopInShop: fields?.excludeShopInShop || false,
    items: {
      floorHeading: fields?.floorHeading || '',
      data:
        fields?.shopByCategoryCards?.map((category) => {
          return {
            imageUrl: category?.fields?.imageMediaUrl || null,
            link: category?.fields?.imageHyperLink || null,
            name: category?.fields?.name || null,
            bigTitle: category?.fields?.bigTitle || null,
          };
        }) || [],
    },
  };
};

export const searchBarKeys = (data) => {
  return data.map((ele) => ele?.searchKeyWord || '');
};

export const shopByCategoryScroll = (args) => {
  const { fields } = args;
  return {
    type: args?.sys?.contentType?.sys?.id || '',
    displayType: fields?.display || '',
    excludeShopInShop: fields?.excludeShopInShop || false,
    items: {
      floorHeading: fields?.floorHeading || '',
      data:
        fields?.shopByCategoryScrollCards?.map((category) => {
          return {
            imageUrl: category?.fields?.imageMediaUrl || null,
            link: category?.fields?.imageHyperLink || null,
            name: category?.fields?.name || null,
            bigTitle: category?.fields?.bigTitle || null,
          };
        }) || [],
    },
  };
};

export const shopByCategoryScrollRect = (args) => {
  const { fields } = args;
  return {
    type: args?.sys?.contentType?.sys?.id || '',
    displayType: fields?.display || '',
    excludeShopInShop: fields?.excludeShopInShop || false,
    items: {
      floorHeading: fields?.floorHeading || '',
      data:
        fields?.shopByCategoryScrollv2cards?.map((category) => {
          return {
            imageUrl: category?.fields?.imageMediaUrl || null,
            link: category?.fields?.imageHyperLink || null,
            name: category?.fields?.name || null,
            bigTitle: category?.fields?.bigTitle || null,
          };
        }) || [],
    },
  };
};

export const dualCardText = (floor) => {
  const data = {
    heading: floor?.fields?.name,
    banners: floor?.fields?.dualCardTextFloor?.map((banner) => ({
      id: banner?.sys?.id,
      name: banner?.fields?.name,
      dualCardLogo: banner?.fields?.dualCardLogo,
      categoryDescriptionTitle: banner?.fields?.dualCardDescription?.fields?.categoryDescriptionTitle,
      categoryDescriptionText: banner?.fields?.dualCardDescription?.fields?.categoryDescriptionText,
    })),
  };
  return {
    type: floor?.sys?.contentType?.sys?.id,
    displayType: floor?.fields?.display,
    data,
  };
};

export const quickGuideFloor = (floor) => {
  const data = {
    heading: floor?.fields?.title,
    banners: floor?.fields?.quickGuideContent?.map((banner) => ({
      id: banner?.sys?.id,
      name: banner?.fields?.name,
      quickGuideTicklogo: banner?.fields?.quickGuideTicklogo,
      quickGuideImgLogo: banner?.fields?.quickGuideImgLogo,
      categoryDescriptionTitle: banner?.fields?.quickGuideDescription?.fields?.categoryDescriptionTitle,
      categoryDescriptionText: banner?.fields?.quickGuideDescription?.fields?.categoryDescriptionText,
    })),
  };
  return {
    type: floor?.sys?.contentType?.sys?.id,
    displayType: floor?.fields?.display,
    data,
  };
};

export const promiseV2 = (floor) => {
  const data = {
    heading: floor?.fields?.name,
    banners: floor?.fields?.promiseCardsV2?.map((banner) => ({
      name: banner?.fields?.name,
      logoUrl: banner?.fields?.logoUrl,
    })),
  };
  return {
    type: floor?.sys?.contentType?.sys?.id,
    displayType: floor?.fields?.display,
    data,
  };
};

export const policyFaqs = (floor) => {
  const data = {
    heading: floor?.fields?.name,
    banners: floor?.fields?.faqList?.map((banner) => ({
      id: banner?.sys?.id,
      name: banner?.fields?.name,
      data: {
        description: [
          {
            fields: {
              categoryDescriptionTitle: banner?.fields?.categoryDescriptionTitle,
              categoryDescriptionText: banner?.fields?.categoryDescriptionText,
            },
          },
        ],
        changeFont: banner?.fields?.changeFont,
      },
    })),
  };
  return {
    type: floor?.sys?.contentType?.sys?.id,
    displayType: floor?.fields?.display,
    data,
  };
};

export const table = (floor) => {
  const data = {
    heading: floor?.fields?.name,
    banners: floor?.fields?.tableCardData?.map((banner) => ({
      id: banner?.sys?.id,
      tableHeading: banner?.fields?.tableHead,
      tableData: banner?.fields?.tableData,
    })),
  };
  return {
    type: floor?.sys?.contentType?.sys?.id,
    displayType: floor?.fields?.display,
    data,
  };
};

export const CategoryBannersMapper = (args) => {
  const { fields } = args;
  return {
    type: 'categoryBannerfloor',
    data: [fields]
      .map((banner) => {
        return {
          ...banner,
          type: 'categoryBanners',
        };
      })
      .filter((banner) => banner.web || banner.mWeb),
  };
};
