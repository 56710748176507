import { GAEvent_getProductsData, GAProductsDataTypes } from './GAhelpers';
import { CartLine } from './globalEvents/GALocalStorage';

/**
 * This function is an action creator that dispatches a Google Analytics event in Thank You page when a user successfully completes the purchase. It retrieves the details of the products purchased from localStorage set for payment related data such as orderId, method, fulfiller, cartItemsIds, sellingPrice, taxAmount, couponCode, shippingCharge from payment reducer and the remaining required datas from localStorage set from cart page reducer.
 */

export const GAEvent_purchaseComplete = () => {
  try {
    const analyticsData = JSON.parse(window.localStorage.getItem('decathlon_cart_data') || '{}');
    const { paymentData, checkedCartItems } = analyticsData;
    const eventData = GAEvent_getProductsData({ cartLines: checkedCartItems });

    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: 'purchase',
      ecommerce: {
        transaction_id: paymentData?.orderId || '',
        value: paymentData?.sellingPrice || '',
        tax: paymentData?.taxAmount || '',
        shipping: paymentData?.shippingCharge || 0,
        currency: 'INR',
        shipping_tier: paymentData?.fulfiller || '',
        payment_type: paymentData?.method || '',
        coupon: paymentData?.couponCode || '',
        items: eventData?.GA4EventData,
        'product details': eventData?.amplitudeEventData,
        'transaction product count': paymentData?.noOfProductsInCart || '',
        'cart type': paymentData?.cartType || '',
        'delivery country': 'India',
        revenue: paymentData?.cartTotal || '',
        'transaction value local': paymentData?.cartTotal || '',
        'discount value local': paymentData?.discountAmount,
        'discount flag': paymentData?.isCouponApplied,
      },
    });
  } catch {}
};
