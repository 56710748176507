export interface CartLine {
  modelCode: number;
  itemCode: number;
  modelName: string;
  brand: string;
  isStockAvailable: boolean;
  productCategory: {
    categoryId: number;
  };
  selectedArticleInfo: {
    attribute: {
      attributeValue: string;
    };
    priceForFront: {
      slashedPrice: number;
      finalPrice: number;
    };
  };
}
[];

export interface PaymentDataTypes {
  orderId: number;
  method: string;
  fulfiller: string;
  cartItemsIds: [];
  sellingPrice: number;
  taxAmount: number;
  couponCode: string;
  shippingCharge: number;
  noOfProductsInCart: number;
  cartTpe: string;
  cartTotal: number;
  couponAmount: number;
  discountAmount: number;
  isCouponApplied: boolean;
}

export function GAEvent_localStorageData({
  paymentData,
  cartLines,
  checkedCartItems,
}: { paymentData?: PaymentDataTypes; cartLines?: CartLine[]; checkedCartItems?: CartLine[] } = {}) {
  const existingGAEventData: { paymentData?: PaymentDataTypes; cartLines?: CartLine[]; checkedCartItems?: CartLine[] } =
    JSON.parse(localStorage.getItem('decathlon_cart_data') || '{}');

  const GAEventData = { ...existingGAEventData };
  if (paymentData) {
    GAEventData.paymentData = paymentData;
  }
  if (cartLines) {
    GAEventData.cartLines = cartLines;
  }
  if (checkedCartItems) {
    GAEventData.checkedCartItems = checkedCartItems;
  }

  localStorage.setItem('decathlon_cart_data', JSON.stringify(GAEventData));

  return GAEventData;
}
