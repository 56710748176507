import { IDY_AddToCart, IDY_Login, IDY_Purchase, IDY_RemoveFromCart, IDY_Signup } from './dynamicYield.types';

const isProduction = process.env.NEXT_PUBLIC_ENVIRONMENT === 'PRODUCTION';

/**
 * DY Add to Cart
 *
 * @info https://dy.dev/docs/add-to-cart
 *
 * @param {number} value - The total monetary value of the event (item or items added that triggered the event only).
 * @param {string} productId - The SKU exactly as it appears in the product feed
 * @param {number} quantity - The total number of items that were added to cart
 * @param {string} size - The cart's current state, including the last item added. Products are ordered from the first added to the cart to the most recent.
 * @param {object[]} cart - The total items data
 * @returns {undefined} this function is used to track add to cart event.
 *
 */

export const DY_AddToCart = ({ value, productId, quantity, size, cart }: IDY_AddToCart): undefined => {
  const properties = {
    dyType: 'add-to-cart-v1',
    value,
    currency: 'INR',
    productId: String(productId),
    quantity,
    size,
    cart,
  };

  if (!isProduction) {
    console.log(`DY Event ${process.env.NEXT_PUBLIC_ENVIRONMENT}: add-to-cart-v1`, properties);
  }

  window.DY.API('event', {
    name: 'Add to Cart',
    properties,
  });
};

/**
 * DY Remove from Cart
 *
 * @info https://dy.dev/docs/remove-from-cart
 *
 * @param {number} value - The total monetary value of the event (item or items added that triggered the event only).
 * @param {string} productId - The SKU exactly as it appears in the product feed
 * @param {number} quantity - The total number of items that were added to cart
 * @param {string} size - The cart's current state, including the last item added. Products are ordered from the first added to the cart to the most recent.
 * @param {object[]} cart - The total items data
 * @returns {undefined} this function is used to track add to cart event.
 *
 */
export const DY_RemoveFromCart = ({ value, productId, quantity, size, cart }: IDY_RemoveFromCart): undefined => {
  const properties = {
    dyType: 'remove-from-cart-v1',
    value,
    currency: 'INR',
    productId: String(productId),
    quantity,
    size,
    cart,
  };

  if (!isProduction) {
    console.log(`DY Event ${process.env.NEXT_PUBLIC_ENVIRONMENT}: remove-from-cart-v1`, properties);
  }

  window.DY.API('event', {
    name: 'Remove from Cart',
    properties,
  });
};

/**
 * DY Login
 *
 * @info https://dy.dev/docs/login
 *
 * @param {string} cuid - User identifier value
 * @returns {undefined} this function is used to track login event.
 *
 */
export const DY_Login = ({ cuid }: IDY_Login): undefined => {
  const properties = {
    dyType: 'login-v1',
    cuid,
    cuidType: 'EcommerceID',
  };

  if (!isProduction) {
    console.log(`DY Event ${process.env.NEXT_PUBLIC_ENVIRONMENT}: login-v1`, properties);
  }

  window.DY.API('event', {
    name: 'Login',
    properties,
  });
};

/**
 * DY Signup
 *
 * @info https://dy.dev/docs/signup
 *
 * @param {string} cuid - User identifier value
 * @returns {undefined} this function is used to track signup event.
 *
 */
export const DY_Signup = ({ cuid }: IDY_Signup): undefined => {
  const properties = {
    dyType: 'signup-v1',
    cuid,
    cuidType: 'EcommerceID',
  };

  if (!isProduction) {
    console.log(`DY Event ${process.env.NEXT_PUBLIC_ENVIRONMENT}: signup-v1`, properties);
  }

  window.DY.API('event', {
    name: 'Signup',
    properties,
  });
};

/**
 * DY Purchase
 *
 * @info https://dy.dev/docs/purchase
 *
 * @param {string} cuid - User identifier value
 * @returns {undefined} this function is used to track purchase event.
 *
 */
export const DY_Purchase = (): undefined => {
  const analyticsData = JSON.parse(window.localStorage.getItem('decathlon_cart_data') || '{}');
  const { orderId } = analyticsData?.paymentData || {};
  const { cartTotal, cartLines } = analyticsData?.cartLines || {};

  const properties: IDY_Purchase = {
    dyType: 'purchase-v1', // Identifies this event as a purchase
    value: cartTotal,
    currency: 'INR',
    uniqueTransactionId: String(orderId),
    cart: cartLines?.map((item: any) => {
      return {
        productId: String(item?.modelCode),
        quantity: item?.quantity,
        itemPrice: item?.selectedArticleInfo?.priceForFront?.finalPrice,
        size: item?.selectedArticleInfo?.attribute?.attributeValue,
      };
    }),
  };

  if (!isProduction) {
    console.log(`DY Event ${process.env.NEXT_PUBLIC_ENVIRONMENT}: purchase-v1`, properties);
  }

  window.DY.API('event', {
    name: 'Purchase',
    properties,
  });
};

/**
 * DY KeywordSearch
 *
 * @param {string} value - User searched value
 * @returns {undefined} this function is used to track KeywordSearch event.
 *
 */
export const DY_KeywordSearch = ({ type, value }: any): undefined => {
  const properties = {
    dyType: 'keyword-search-v1',
    keywords: String(value),
  };

  if (!isProduction) {
    console.log(`DY Event ${process.env.NEXT_PUBLIC_ENVIRONMENT}: KeywordSearch`, properties);
  }

  window.DY.API('event', {
    name: 'Keyword Search',
    properties,
  });
};

/**
 * DY FilterItems
 *
 * @param {string} name - Filter name.
 * @param {string} value - Filter selected value.
 * @returns {undefined} this function is used to track FilterItems event.
 *
 */
export const DY_FilterItems = ({ name, value, min, max }: any): undefined => {
  const properties: any = {
    dyType: 'filter-items-v1',
    filterType: name,
    filterStringValue: value,
  };

  if (!isProduction) {
    console.log(`DY Event ${process.env.NEXT_PUBLIC_ENVIRONMENT}: FilterItems`, properties);
  }

  window.DY.API('event', {
    name: 'Filter Items',
    properties,
  });
};
