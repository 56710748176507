import fetchApi from '@/src/Middleware/fetchApi';
import { decrypt } from '@/src/Middleware/helpers';
import { getCookie } from '@/src/Utils/Cookies';
import { redirectToSignInPage } from '@/src/Utils/DKTLogin';
import productPageActions from './ProductPage';
import { showToastMessage } from './toastMessageActions';

export const WISHLIST_ITEM_ADDED = 'WISHLIST_ITEM_ADDED';
export const wishlistItemAdded = (payload) => {
  return {
    type: WISHLIST_ITEM_ADDED,
    payload,
  };
};

export const WISHLIST_FETCHED = 'WISHLIST_FETCHED';
export const wishlistFetched = (payload) => {
  return {
    type: WISHLIST_FETCHED,
    payload,
  };
};

export const WISHLIST_FILTERED = 'WISHLIST_FILTERED';
export const wishlistFiltered = (payload) => {
  return {
    type: WISHLIST_FILTERED,
    payload,
  };
};

export const TOGGLE_WISHLIST_LOADING = 'TOGGLE_WISHLIST_LOADING';
export const toggleWishlistLoading = (payload) => {
  return {
    type: TOGGLE_WISHLIST_LOADING,
    payload,
  };
};

export const TOGGLE_SKELETON_LOADING = 'TOGGLE_SKELETON_LOADING';
export const toggleSkeletonLoading = (payload) => {
  return {
    type: TOGGLE_SKELETON_LOADING,
    payload,
  };
};

export const TOGGLE_ERROR_PAGE = 'WISHLIST_FILTERED';
export const toggleWishlistErrorPage = (payload) => {
  return {
    type: TOGGLE_ERROR_PAGE,
    payload,
  };
};

export const PAGE_COUNT = 'PAGE_COUNT';
export const updatePageCount = (payload) => {
  return {
    type: PAGE_COUNT,
    payload,
  };
};

export const PAGE_NUMBER = 'PAGE_NUMBER';
export const updateCurrentPage = (payload) => {
  return {
    type: PAGE_NUMBER,
    payload,
  };
};

export const PRODUCT_COUNT = 'PRODUCT_COUNT';
export const updateProductCount = (payload) => {
  return {
    type: PRODUCT_COUNT,
    payload,
  };
};

const getSecondsSinceEpoch = () => {
  const now = new Date();
  return Math.round(now.getTime() / 1000);
};

const updateWishlistLocalStorage = (modelIds) => {
  localStorage.setItem('wishlistItems', JSON.stringify({ modelIds }));
  localStorage.setItem('wishlistEpoch', getSecondsSinceEpoch());
};

export const fetchWLWithoutStock = () => {
  const wishlistEpoch = localStorage.getItem('wishlistEpoch');
  const isWishlistInLocalStorage =
    wishlistEpoch !== null
      ? getSecondsSinceEpoch() - wishlistEpoch < process.env.NEXT_PUBLIC_WISHLIST_BROWSER_CACHE_TIME_SECONDS
      : false;
  return async (dispatch) => {
    if (!isWishlistInLocalStorage) {
      const apiResponse = await fetchApi(null, '/api/wishlist', {
        data: {
          stockCheck: false,
        },
      });
      if (apiResponse?.status && apiResponse?.errorCode !== 'W004') {
        updateWishlistLocalStorage(apiResponse?.data?.modelIds);
      }
    }
    return null;
  };
};

export const fetchWLWithStock = () => {
  return async (dispatch, getState) => {
    dispatch(toggleSkeletonLoading(true));

    const apiResponse = await fetchApi(null, '/api/wishlist', {
      data: {
        stockCheck: true,
        pageNumber: getState().wishlistReducer?.page + 1,
      },
    });

    const loadedModelIds = apiResponse?.data?.modelInfoList?.map((item) => item.modelId);
    const productsData = await dispatch(productPageActions.getProductsDetail(loadedModelIds));

    if (apiResponse?.status && apiResponse?.data?.modelInfoList?.length) {
      const inStock = { modelIds: [], modelInfoList: [] };
      const outOfStock = { modelIds: [], modelInfoList: [] };
      const items = apiResponse?.data?.modelInfoList || [];

      // AMI logic
      const result = productsData?.map((item) => item[0]);
      const tempProductResponse = items;
      for (let i = 0; i < result?.length; i++) {
        for (let j = 0; j < items?.length; j++) {
          if (String(result[i].modelId) === items[j]?.modelId) {
            result[i].isInStock = items[j]?.productResponse?.stockAvailability;
            tempProductResponse[j].productResponse.sellerId = result[i]?.sellerDetail?.sellerId;
            tempProductResponse[j].productResponse.additionalWeight = result[i]?.additionalWeight;
            tempProductResponse[j].productResponse.additionalShippingFee = result[i]?.additionalShippingFee;
            tempProductResponse[j].productResponse.description = result[i]?.description;
          }
        }
      }

      tempProductResponse?.forEach((item) => {
        if (item?.productResponse?.stockAvailability) {
          inStock.modelIds.push(item?.modelId);
          inStock.modelInfoList.push(item);
        } else {
          outOfStock.modelIds.push(item?.modelId);
          outOfStock.modelInfoList.push(item);
        }
      });

      const productsInStock = await dispatch(productPageActions.getSpecificProductsDetail(inStock.modelIds));
      const productsOutOfStock = await dispatch(productPageActions.getSpecificProductsDetail(outOfStock.modelIds));

      dispatch(
        wishlistFetched({
          productsInStock: {
            pim: inStock?.modelInfoList,
            wcms: productsInStock,
          },
          productsOutOfStock: {
            pim: outOfStock?.modelInfoList,
            wcms: productsOutOfStock,
          },
          pageCount: parseInt(apiResponse?.data?.pageCount, 10),
        }),
      );

      if (apiResponse?.data?.modelIds?.length) {
        updateWishlistLocalStorage(apiResponse?.data?.modelIds);
        dispatch(updateProductCount(apiResponse?.data?.modelIds?.length));
      }

      dispatch(updateCurrentPage(getState().wishlistReducer?.page + 1));
      dispatch(toggleSkeletonLoading(false));
    } else {
      updateWishlistLocalStorage(apiResponse?.data?.modelIds);
      dispatch(updateProductCount(apiResponse?.data?.modelIds?.length));
      dispatch(toggleSkeletonLoading(false));
    }
  };
};

export const addToWishlist = (modelIdList) => {
  // add model to localStorage
  const existingModelIds = JSON.parse(localStorage.getItem('wishlistItems'));
  existingModelIds.modelIds?.push(modelIdList[0]);

  updateWishlistLocalStorage(existingModelIds.modelIds);
  return async (dispatch) => {
    const apiResponse = await fetchApi(null, '/api/wishlist/add', {
      data: {
        modelIdList,
      },
    });

    if (apiResponse?.status) {
      dispatch(updateProductCount(apiResponse?.data?.modelIdList?.length));
    } else if (apiResponse?.statusCode !== 'W002') {
      const localStorageModelIds = JSON.parse(localStorage.getItem('wishlistItems')).modelIds;
      localStorageModelIds?.splice(localStorageModelIds.indexOf(modelIdList[0]), 1);
      updateWishlistLocalStorage(localStorageModelIds);
    }

    return apiResponse;
  };
};

export const removeFromWishlist = (modelId) => {
  return async (dispatch) => {
    const apiResponse = await fetchApi(null, '/api/wishlist/delete', {
      data: {
        modelId,
      },
    });
    if (apiResponse?.status) {
      updateWishlistLocalStorage(apiResponse?.data?.modelIdList);
      dispatch(updateProductCount(apiResponse?.data?.modelIdList?.length));
    }
    return apiResponse;
  };
};

export const removeAllFromWishlist = (modelIdList) => {
  return async (dispatch) => {
    const customerId = decrypt(getCookie('dkt_loyaltyCard')) || null;
    try {
      const apiResponse = await fetchApi(null, '/api/wishlist/delete-all', {
        data: {
          customerId,
          modelIdList,
        },
      });
      if (apiResponse?.status) {
        updateWishlistLocalStorage(apiResponse?.data?.modelIdList);
        dispatch(updateProductCount(apiResponse?.data?.modelIdList?.length));
        dispatch({
          type: 'OUTOFSTOCK_CLEAR',
        });
        return apiResponse?.data;
      }
      return apiResponse?.message;
    } catch (err) {
      return null;
    }
  };
};

export const fetchWishlistProductData = (modelId) => {
  return async () => {
    try {
      const apiResponse = await fetchApi(null, '/api/wishlist/fetch-product-data', {
        data: {
          modelId,
        },
      });
      if (apiResponse?.status) {
        return apiResponse?.data;
      }
      return apiResponse?.message;
    } catch (err) {
      return null;
    }
  };
};

export const WISHLIST_UNMOUNT = 'WISHLIST_UNMOUNT';
export const wishlistUnmountAction = () => (dispatch) => {
  dispatch({
    type: WISHLIST_UNMOUNT,
  });
};

function removeDuplicates(data) {
  return data.filter((value, index) => data.indexOf(value === index));
}

export const moveItemToWishlist =
  ({ ctx = null, modelId = false, cartLineId = false } = {}) =>
  async (dispatch, getState) => {
    const isLoggedIn = getCookie('dkt_isLoggedIn');
    const {
      cartItems: { sportyRewardsStatus },
    } = getState()?.reducer?.cartPage;

    if (!isLoggedIn) {
      redirectToSignInPage('cart');
      return false;
    }

    const cartLines = getState()?.reducer?.cartPage?.cartItems?.cartLines;
    const wishlistItems = JSON.parse(localStorage.getItem('wishlistItems'));
    let modelIdList;
    let cartLineIds;

    if (modelId && cartLineId) {
      modelIdList = [String(modelId)];
      cartLineIds = [cartLineId];
    } else if (cartLines?.length) {
      modelIdList = removeDuplicates(cartLines.map((i) => String(i.modelCode)));
      cartLineIds = removeDuplicates(cartLines.map((i) => i.cartLineId));
    }
    const isExistingWishlistItem = localStorage.getItem('wishlistItems')?.includes(`"${modelIdList.join('","')}"`);

    updateWishlistLocalStorage([...(wishlistItems?.modelIds || []), ...modelIdList]);

    const apiResponse = await fetchApi(ctx, '/api/wishlist/move', {
      authenticationRequired: true,
      retry: 1,
      retryStatusCodes: [500],
      data: { modelIdList, cartLineIds, isSportyRewardsEnabled: sportyRewardsStatus },
    });

    if (apiResponse?.status) {
      dispatch(
        showToastMessage({
          type: 'toast',
          appearance: isExistingWishlistItem ? 'info' : 'success',
          message: isExistingWishlistItem ? 'Product is already wishlisted' : 'The selected item(s) moved to wishlist',
          timeout: 3000,
        }),
      );

      dispatch({
        type: 'GET_CART',
        payload: apiResponse.cart,
      });
    } else if (apiResponse?.statusCode !== 401) {
      dispatch(
        showToastMessage({
          type: 'toast',
          message: apiResponse?.message,
          timeout: 3000,
        }),
      );
    }

    if (!apiResponse?.status && apiResponse?.service === 'wishlist') {
      updateWishlistLocalStorage(wishlistItems?.modelIds);
    }

    return apiResponse;
  };
