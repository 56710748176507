const gtmScript = () => {
  if (typeof window !== 'object') return;
  (function (w, d, s, l) {
    w[l] = w[l] || [];
    w[l]?.push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
    let f = d.getElementsByTagName(s)[0],
      j = d.createElement(s),
      dl = l != 'dataLayer' ? '&l=' + l : '';
    j.defer = true;
    j.src = 'https://www.googletagmanager.com/gtm.js?id=' + process.env.NEXT_PUBLIC_GTM_ID + dl;
    f.parentNode.insertBefore(j, f);
  })(window, document, 'script', 'dataLayer');
};
const loadCleverTap = () => {
  if (typeof window.clevertap === 'object') return;
  if (process.env.NEXT_PUBLIC_CLEVERTAP_ID) {
    let clevertap = {
      event: [],
      profile: [],
      account: [],
      onUserLogin: [],
      notifications: [],
      privacy: [],
    };
    clevertap.account.push({ id: process.env.NEXT_PUBLIC_CLEVERTAP_ID });
    clevertap.privacy.push({ optOut: false }); //set the flag to true, if the user of the device opts out of sharing their data
    clevertap.privacy.push({ useIP: false }); //set the flag to true, if the user agrees to share their IP data
    window.clevertap = clevertap;
    (function () {
      let wzrk = document.createElement('script');
      wzrk.type = 'text/javascript';
      wzrk.defer = true;
      wzrk.src =
        ('https:' == document.location.protocol
          ? 'https://d2r1yp2w7bby2u.cloudfront.net'
          : 'http://static.clevertap.com') + '/js/a.js';
      let s = document.getElementsByTagName('script')[0];
      s.parentNode.insertBefore(wzrk, s);
    })();
  }
};

export { gtmScript, loadCleverTap };
