export type GAProductsDataTypes = {
  itemId: number;
  name: string;
  modelCode: number;
  modelcode: number;
  modelId: number;
  model_id: number;
  id: number;
  articleIds: [];
  itemCode: string;
  modelName: string;
  itemName: string;
  productName: string;
  name_en: string;
  description: {
    productName: string;
  };
  itemSize: string;
  couponCode: string;
  selectedArticleInfo: {
    priceForFront: {
      slashedPrice: number;
      finalPrice: number;
      discountPercentage: number;
    };
    attribute: {
      attributeValue: string;
    };
  };
  priceForFront: {
    discountPercentage: number;
    slashedPrice: number;
    finalPrice: number;
  };
  originalPrice: number;
  slashedPrice: number;
  sellingPrice: number;
  price: number;
  brand: string;
  manufacturer_en: string;
  isStockAvailable: string;
  quantity: number;
  productCategory: {
    name: string;
    categoryId: number;
  };
  productFilers: {
    key: string;
    values: any;
  }[];
  articles: {
    attribute: {
      attributeValue: string;
    };
    isStockAvailable: boolean;
    stockNotification: {
      notify: boolean;
    };
  }[];
  breadcrumbs: {
    name: string;
  }[];
  sportGroup: string;
  variants: string;
  nature: {
    natureName: string;
  };
  sellerDetail: {
    sellerName: string;
  };
  isDecathlonProduct: boolean;
  isDecathlon: boolean;
  lifeCycle: number;
  discount: number;
  discountPercentage: number;
}[];

export type GAQuantityType = {
  quantity: number;
};

export type GAPaymentCartDetailsTypes = {
  lines?: GAProductsDataTypes[];
};

export type GAPurchaseCompletedEventData = {
  couponCode: string;
  cartParts?: {
    lines?: GAProductsDataTypes[];
  }[];
};

export type GASelectedArticleDataTypes = {
  articleId: number;
  attribute: {
    attributeValue: string;
  };
  priceForFront: {
    discountPercentage: number;
  };
};

/**
 * This function takes various parameters representing product details and formats them into an array suitable for Google Analytics events. The function is designed to handle different sources of product information, such as PIM responses or ModelCardPIM responses.
 *
 * @param {Object[]} cartLines - The product details of the items selected or displayed - PIM Response or ModelCardPim response
 * @param {number} quantity - The quantity of the selected product
 * @param {object} selectedSize - The product stock and attribute information - PIM Response or ModelCardPim response
 * @param {string} locationId - The pickup details of the selected product when the chosen delivery option is Pickup from Store, else the value is null.
 * @returns {Object[]} Returning an array containing product details suitable for all the GA events.
 */

export const GAEvent_getProductsData = ({
  cartLines,
  quantity,
  selectedArticle,
  locationId,
}: {
  cartLines: GAProductsDataTypes;
  quantity?: number;
  selectedArticle?: GASelectedArticleDataTypes;
  locationId?: string | undefined;
}) => {
  try {
    const isInStock = cartLines?.map((item) => {
      if (cartLines?.some((line) => line?.articles && line?.articles?.length > 0)) {
        return cartLines?.[0]?.articles?.every((item) => item.isStockAvailable === false)
          ? 'Unavailable'
          : cartLines?.[0]?.articles?.some((item) => item.isStockAvailable === false)
            ? 'Partially Available'
            : 'Available';
      } else if (item?.isStockAvailable !== undefined) {
        return item?.isStockAvailable ? 'In Stock' : 'Out of Stock';
      } else {
        return 'N/A';
      }
    });

    const getSportName = (name: string) =>
      cartLines?.[0]?.productFilers?.find((item) => item?.key === name)?.values?.[0];

    const availableSizes = !cartLines?.[0]?.articles
      ? 'N/A'
      : (cartLines?.[0]?.articles && cartLines?.[0]?.articles?.length) > 0
        ? cartLines?.[0]?.articles?.every(
            (item) => !item?.isStockAvailable && item?.stockNotification?.notify === false,
          )
          ? 'Product discontinued'
          : cartLines?.[0]?.articles?.some((item) => item?.isStockAvailable)
            ? cartLines?.[0]?.articles
                ?.filter((item) => item.isStockAvailable)
                ?.map((item) => item?.attribute?.attributeValue)
                ?.join(',')
            : 'No sizes available'
        : 'One Size';

    const articleIdsProductPage = cartLines?.[0]?.articleIds ? `[${cartLines?.[0].articleIds.join(', ')}]` : '';

    const GA4EventData = cartLines?.map((item, index) => {
      return {
        item_id: item.modelCode || item.modelId || item.modelcode || item.model_id || item.itemId || '',
        item_name:
          item.modelName ||
          item.description?.productName ||
          item.productName ||
          item.itemName ||
          item.name_en ||
          item.name ||
          '',
        affiliation: 'Decathlon Web Store',
        discount:
          (selectedArticle?.priceForFront?.discountPercentage ||
            item?.selectedArticleInfo?.priceForFront?.discountPercentage ||
            item?.priceForFront?.discountPercentage ||
            item?.discountPercentage ||
            item?.discount ||
            0) + '%',
        item_brand: item.brand || item?.manufacturer_en || '',
        item_category: getSportName('SPORT_GROUP')?.toString() || item.sportGroup || 'N/A',
        item_category2:
          selectedArticle?.attribute?.attributeValue ||
          item.itemSize ||
          item?.selectedArticleInfo?.attribute?.attributeValue ||
          availableSizes,
        item_category3: item?.nature?.natureName || 'N/A',
        item_category4: item?.lifeCycle || 'N/A',
        item_category5: isInStock[index],
        item_list_id: item.productCategory?.categoryId || 'N/A',
        location_id: locationId || 'N/A',
        price:
          item.selectedArticleInfo?.priceForFront?.slashedPrice ||
          item.priceForFront?.slashedPrice ||
          item.originalPrice ||
          item.price ||
          item?.slashedPrice ||
          0,
        quantity: item.quantity || quantity || 1,
      };
    });

    const amplitudeEventData = cartLines?.map((item, index) => {
      const isDecathlonKeyPresent = item.hasOwnProperty('isDecathlonProduct') || item.hasOwnProperty('isDecathlon');
      const productType = isDecathlonKeyPresent
        ? item.isDecathlonProduct || item.isDecathlon
          ? 'Decathlon'
          : 'Marketplace'
        : '';
      const selectedArticleId = selectedArticle?.articleId;
      return {
        'sku code': item?.itemCode || selectedArticleId || articleIdsProductPage || 'N/A',
        'product id': item?.modelCode || item?.modelId || item?.modelcode || item?.model_id || item?.id || '',
        name:
          item?.modelName ||
          item?.description?.productName ||
          item?.productName ||
          item?.itemName ||
          item?.name_en ||
          item?.name ||
          '',
        'list price':
          item?.selectedArticleInfo?.priceForFront?.slashedPrice ||
          item?.priceForFront?.slashedPrice ||
          item?.originalPrice ||
          item?.price ||
          item?.slashedPrice ||
          0,
        'display price':
          item?.priceForFront?.finalPrice ||
          item?.selectedArticleInfo?.priceForFront?.finalPrice ||
          item?.price ||
          item.sellingPrice ||
          '',
        quantity: item?.quantity || quantity || 1,
        brand: item?.brand || item?.manufacturer_en || '',
        'category level 1': item.productCategory?.name || 'N/A',
        'category level 3': getSportName('SPORT_GROUP')?.toString() || item?.sportGroup || 'N/A',
        'category level 4': item.nature?.natureName || 'N/A',
        'seller name': item.sellerDetail?.sellerName || 'N/A',
        'product type': productType,
        size:
          selectedArticle?.attribute?.attributeValue ||
          item?.itemSize ||
          item?.selectedArticleInfo?.attribute?.attributeValue ||
          availableSizes,
        availability: isInStock[index],
      };
    });

    return {
      GA4EventData,
      amplitudeEventData,
    };
  } catch {}
};

type pageType = string;
export const GAPageType = () => {
  let page_type: pageType;

  if (typeof window !== 'undefined' && window.location) {
    const location = window?.location?.href;

    switch (true) {
      case location?.includes('type=p'):
        page_type = 'Product Page';
        break;
      case location?.includes('/cart'):
        page_type = 'Cart Page';
        break;
      case location?.includes('type=c'):
        page_type = 'Category Page';
        break;
      case location?.includes('/shop'):
        page_type = 'Shop Page';
        break;
      case location?.includes('/search'):
        page_type = 'Search Page';
        break;
      case location?.includes('/wishlist'):
        page_type = 'Wishlist Page';
        break;
      default:
        page_type = 'Home Page';
    }
    return page_type;
  }
};
