import fetchApi from '@/src/Middleware/fetchApi';
import { showToastMessage } from '@/redux/actions/toastMessageActions';
import { getCookie, setCookie } from '@/src/Utils/Cookies';
import { getContentfulClientEntries, getPlatform } from '../../src/Utils';
import { walletSectionContent } from '@/constant/contentfulConstants';
import { GAEvent_ErrorTracking } from './GAEventsActions/GAErrorTrackingEvents';
import { getSignInPageUrl } from '@/src/Utils/DKTLogin';
import { updateGiftCardAndRazorpayPaymentsTotals } from './PaymentPageActions';

export const WALLET_BALANCE_LOADING = 'WALLET_BALANCE_LOADING';
export const WALLET_FETCHED = 'WALLET_FETCHED';
export const REWARDS_FETCHED = 'REWARDS_FETCHED';
export const WALLET_CREATED = 'WALLET_CREATED';
export const WALLET_BALANCE_FETCHED = 'WALLET_BALANCE_FETCHED';
export const WALLET_BALANCE_REDEEMED = 'WALLET_BALANCE_REDEEMED';
export const WALLET_CONTENTFUL_LOADING = 'WALLET_CONTENTFUL_LOADING';
export const WALLET_CONTENTFUL_FETCHED = 'WALLET_CONTENTFUL_FETCHED';
export const WALLET_ALL_TRANSACTION_TOGGLE = 'WALLET_ALL_TRANSACTION_TOGGLE';
export const TOGGLE_GC_MODAL = 'TOGGLE_GC_MODAL';
export const TOGGLE_WALLET_USE_IN_PAYMENT = 'TOGGLE_WALLET_USE_IN_PAYMENT';
export const WALLET_EXPIRED_FETCHED = 'WALLET_EXPIRED_FETCHED';
export const TRANSACTION_LOADING_ERROR = 'TRANSACTION_LOADING_ERROR';
export const WALLET_BALANCE_LOADING_ERROR = 'WALLET_BALANCE_LOADING_ERROR';
export const WALLET_FEATURE_FLAG = 'WALLET_FEATURE_FLAG';
export const TOGGLE_WALLET_BALANCE_ERROR_MODAL = 'TOGGLE_WALLET_BALANCE_ERROR_MODAL';
export const UPDATE_HISTORY_PAGE = 'UPDATE_HISTORY_PAGE';
export const UPDATE_REWARDS_HISTORY_PAGE = 'UPDATE_REWARDS_HISTORY_PAGE';
export const UPDATE_EXPIRY_PAGE = 'UPDATE_EXPIRY_PAGE';
export const GC_LOADING = 'GC_LOADING';
export const EXPIRED_TRANSACTION_LOADING = 'EXPIRED_TRANSACTION_LOADING';
export const TRANSACTION_LOADING = 'TRANSACTION_LOADING';
export const REWARDS_BALANCE_LOADING_ERROR = 'REWARDS_BALANCE_LOADING_ERROR';

// rewards
export const REWARDS_BALANCE_FETCHED = 'REWARDS_BALANCE_FETCHED';

export const walletApiMapper =
  (ctx = null, url = null, payload = {}) =>
  async (dispatch, getState) => {
    const platform = getPlatform(ctx);
    const apiResponse = await fetchApi(ctx, url, {
      authenticationRequired: true,
      retry: 1,
      retryStatusCodes: [500],
      ...payload,
    });

    if (apiResponse?.statusCode === 401) {
      dispatch(
        showToastMessage({
          type: 'popup',
          heading: 'Login expired',
          message: 'Please login again to proceed.',
          rightButtons: {
            status: true,
            text: platform === 'APP' ? 'Try again' : 'Login',
            redirect: platform === 'APP' ? '/thankyou?error=exit' : getSignInPageUrl('payment'),
          },
        }),
      );
    } else if (apiResponse?.statusCode === 409 || ['W001', 'W002'].includes(apiResponse?.errorCode || ' ')) {
      dispatch(
        showToastMessage({
          type: 'popup',
          heading: 'Error Encountered!',
          message: 'Something went wrong! Try reloading the page once.',
          rightButtons: {
            status: true,
            color: 'red',
            backIcon: true,
            redirect: '',
          },
        }),
      );
    } else if (['WA325'].includes(apiResponse?.errorCode)) {
      dispatch(
        toggleWalletFeatureFlag(false, apiResponse?.apiError?.errorMessage ?? 'Wallet is temporarily disabled for WEB'),
      );
    }

    if (!apiResponse?.status) {
      GAEvent_ErrorTracking(
        apiResponse?.statusCode,
        apiResponse?.errorCode,
        apiResponse?.apiError?.errorMessage ?? 'Wallet is temporarily disabled for WEB',
        'Wallet',
      );
    }

    return apiResponse;
  };

export const walletContentfulLoading = (payload) => {
  return {
    type: WALLET_CONTENTFUL_LOADING,
    payload,
  };
};

export const walletBalanceLoading = (payload) => {
  return {
    type: WALLET_BALANCE_LOADING,
    payload,
  };
};

export const walletFetched = (payload, pageIndex) => {
  return {
    type: WALLET_FETCHED,
    payload: { ...payload, pageIndex },
  };
};

export const rewardsFetched = (payload, pageIndex) => {
  return {
    type: REWARDS_FETCHED,
    payload: { ...payload, pageIndex },
  };
};

export const walletExpiredFetched = (payload, pageIndex) => {
  return {
    type: WALLET_EXPIRED_FETCHED,
    payload: { ...payload, pageIndex },
  };
};

export const walletCreated = (payload) => {
  return {
    type: WALLET_CREATED,
    payload,
  };
};

export const walletBalanceFetched = (payload) => {
  return {
    type: WALLET_BALANCE_FETCHED,
    payload,
  };
};

export const rewardsBalanceFetched = (payload) => {
  return {
    type: REWARDS_BALANCE_FETCHED,
    payload,
  };
};

export const walletBalanceRedeemed = (payload) => {
  return {
    type: WALLET_BALANCE_REDEEMED,
    payload,
  };
};

export const walletContentfulFetched = (payload) => {
  return {
    type: WALLET_CONTENTFUL_FETCHED,
    payload,
  };
};

export const toggleAllTransactionsScreenOpen = (payload) => {
  return {
    type: WALLET_ALL_TRANSACTION_TOGGLE,
    payload: payload ?? true,
  };
};

export const toggleGiftCardScreenOpen = () => {
  return {
    type: TOGGLE_GC_MODAL,
  };
};

export const toggleWalletUseInPayment = (payload) => {
  return async (dispatch, getState) => {
    const cartTotal = getState()?.paymentReducer?.cartTotal;
    const balanceAmount = getState()?.wallet?.balanceAmount;

    dispatch({
      type: TOGGLE_WALLET_USE_IN_PAYMENT,
      payload: {
        status: payload,
        cartTotal,
        balanceAmount,
      },
    });
    dispatch(updateGiftCardAndRazorpayPaymentsTotals());
  };
};

export const updateHistoryPageNumber = (payload) => {
  return {
    type: UPDATE_HISTORY_PAGE,
    payload,
  };
};

export const updateRewardsHistoryPageNumber = (payload) => {
  return {
    type: UPDATE_REWARDS_HISTORY_PAGE,
    payload,
  };
};

export const updateExpiryPageNumber = (payload) => {
  return {
    type: UPDATE_EXPIRY_PAGE,
    payload,
  };
};

export const transactionLoader = (payload) => {
  return {
    type: TRANSACTION_LOADING,
    payload,
  };
};

export const expiredTransactionLoader = (payload) => {
  return {
    type: EXPIRED_TRANSACTION_LOADING,
    payload,
  };
};

export const gcAddingLoader = (payload) => {
  return {
    type: GC_LOADING,
    payload,
  };
};

export const transactionLoadingError = (payload) => {
  return {
    type: TRANSACTION_LOADING_ERROR,
    payload,
  };
};

export const balanceLoadingError = (payload) => {
  return {
    type: WALLET_BALANCE_LOADING_ERROR,
    payload,
  };
};

export const rewardsBalanceLoadingError = (payload) => {
  return {
    type: REWARDS_BALANCE_LOADING_ERROR,
    payload,
  };
};

export const toggleWalletBalanceErrorModal = (payload) => {
  return {
    type: TOGGLE_WALLET_BALANCE_ERROR_MODAL,
    payload,
  };
};

export const toggleWalletFeatureFlag = (enabled, message) => {
  return {
    type: WALLET_FEATURE_FLAG,
    payload: {
      enabled,
      message,
    },
  };
};
// actions start here
export const getContentfulData = (pageType = 'wallet') => {
  return async (dispatch) => {
    try {
      await dispatch(walletContentfulLoading(true));
      const walletContentfulData = await getContentfulClientEntries({
        content_type: walletSectionContent.type,
        select: 'fields',
      });

      // console.log({ walletContentfulData });
      const clearData = (data) => {
        return data?.map((d) => {
          const { fields } = d;
          return fields;
        });
      };

      if (walletContentfulData?.items?.length) {
        const index = pageType === 'wallet' ? 1 : 0;
        let { mainTitle = {}, notes = [], benefits = [] } = walletContentfulData?.items[index]?.fields ?? {};

        [mainTitle] = clearData([mainTitle]);
        notes = clearData(notes);
        benefits = clearData(benefits);

        await dispatch(
          walletContentfulFetched({
            title: mainTitle?.title,
            subTitle: mainTitle?.subTitle,
            notes,
            benefits,
          }),
        );

        await dispatch(walletContentfulLoading(false));
      }
    } catch (error) {}
  };
};

export const getWalletHistory = (pageIndex, noOfTransactions, isExpired = false) => {
  return async (dispatch) => {
    if (!getCookie('walletId')) {
      await dispatch(createWallet());
    }
    if (!isExpired) dispatch(transactionLoader(true));
    else dispatch(expiredTransactionLoader(true));
    const apiResponse = await dispatch(
      walletApiMapper(null, '/api/wallet', {
        data: {
          noOfTransactions: noOfTransactions || 10,
          pageIndex: pageIndex || 0,
          walletName: 'sportycash',
          transactionType: isExpired ? 'transfer' : null,
        },
      }),
    );

    if (apiResponse?.status) {
      dispatch(transactionLoadingError(false));
      if (!isExpired) {
        dispatch(walletFetched(apiResponse?.data, pageIndex));
        dispatch(updateHistoryPageNumber(pageIndex));
      } else {
        dispatch(walletExpiredFetched(apiResponse?.data, pageIndex));
        dispatch(updateExpiryPageNumber(pageIndex));
      }
    } else {
      dispatch(transactionLoadingError(true));
      if (!isExpired) dispatch(transactionLoader(false));
      else dispatch(expiredTransactionLoader(false));
    }

    return apiResponse;
  };
};

export const getRewardsHistory = (pageIndex, noOfTransactions) => {
  return async (dispatch) => {
    if (!getCookie('walletId')) {
      await dispatch(createWallet());
    }
    dispatch(transactionLoader(true));
    const apiResponse = await dispatch(
      walletApiMapper(null, '/api/wallet', {
        data: {
          noOfTransactions: noOfTransactions || 10,
          pageIndex: pageIndex || 0,
          walletName: 'sportyrewards',
          transactionType: null,
        },
      }),
    );

    if (apiResponse?.status) {
      dispatch(transactionLoadingError(false));
      dispatch(rewardsFetched(apiResponse?.data, pageIndex));
      dispatch(updateRewardsHistoryPageNumber(pageIndex));
    } else {
      dispatch(transactionLoadingError(true));
      dispatch(transactionLoader(false));
    }

    return apiResponse;
  };
};

export const createWallet = () => {
  return async (dispatch) => {
    dispatch(walletBalanceLoading(true));
    const apiResponse = await dispatch(walletApiMapper(null, '/api/wallet/create'));
    if (apiResponse?.status) {
      dispatch(walletCreated(apiResponse?.data));
      setCookie('walletId', apiResponse?.data?.walletId);
    }

    dispatch(walletBalanceLoading(false));
    return apiResponse;
  };
};

export const getWalletBalance = (walletName) => {
  return async (dispatch, getState) => {
    const cartTotal = getState()?.paymentReducer?.cartTotal;

    if (!getCookie('walletId')) {
      await dispatch(createWallet());
    }
    dispatch(walletBalanceLoading(true));
    const balanceApiResponse = await dispatch(walletApiMapper(null, `/api/wallet/balance`, { data: { walletName } }));

    if (balanceApiResponse?.status) {
      if (walletName === 'sportycash') {
        dispatch(walletBalanceFetched({ ...balanceApiResponse?.data, cartTotal }));
      } else {
        dispatch(rewardsBalanceFetched({ ...balanceApiResponse?.data }));
      }
    } else {
      if (walletName === 'sportycash') {
        dispatch(balanceLoadingError(true));
      } else {
        dispatch(rewardsBalanceLoadingError(true));
      }
      balanceApiResponse?.errorCode !== 'WA325' && dispatch(toggleWalletBalanceErrorModal(true));
    }
  };
};
