const initialState = {
  paymentPageLoader: true,
  razorpayKey: '',
  customerTokens: [],
  customerId: '',
  contact: '',
  email: '',
  status: false,
  isGooglePayIntentAvailable: false,
  isEligibleForCRED: false,
  CREDEligibilityDescription: 'Pay using your saved credit cards for instant checkout',
  availableIntentApps: [],
  razorpayOptions: {},
  cartDetails: {},
  cartDetailsLoader: true,
  cartTotal: 0,
  razorpayPaymentTotal: 0,
  isRazorPayPaymentDisabled: true,
  giftCards: [],
  giftCardPaymentTotal: 0,
  paymentDowntime: {},
  paymentSummaryDetails: {},
  paymentSummaryDetailsLoader: true,
  payLaterEligibility: [],
  payLaterEligibilityApiFailed: null,
  payLaterEligibilityApiLoader: true,
  paymentConfirmationModalToggle: false,
  disablePaymentConfirmationModal: false,
  selectedPaymentMethod: '',
  activePaymentOption: '',
  paymentAbortController: {},
  sequentialPaymentInitApis: {
    isCartApiLoading: true,
    cartApiResponse: {},
    isWalletApiLoading: true,
    isCustomerApiLoading: true,
    isPaymentApiLoading: true,
    isRazorpayOptionLoading: true,
  },
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case 'PAYMENT_INIT_LOADER':
      return {
        ...state,
        paymentPageLoader: payload,
      };
    case 'PAYMENT_INIT':
      return {
        ...state,
        paymentPageLoader: false,
        razorpayKey: payload?.razorpayKey || '',
        customerTokens: payload?.customerTokens || '',
        customerId: payload?.customerId || '',
        contact: payload?.contact || '',
        email: payload?.email || '',
        status: true,
        isGooglePayIntentAvailable: payload?.isGooglePayIntentAvailable || false,
        isEligibleForCRED: payload?.isEligibleForCRED || false,
        CREDEligibilityDescription: payload?.CREDEligibilityDescription || '',
        availableIntentApps: payload?.availableIntentApps || [],
        razorpayOptions: payload?.razorpayOptions || {},
      };
    case 'PAYMENT_DOWNTIME_':
      return {
        ...state,
        paymentDowntime: payload || {},
      };
    case 'PAY_LATER_ELIGIBILITY':
      return {
        ...state,
        payLaterEligibility: payload?.payment || [],
        payLaterEligibilityApiFailed: !payload?.status || payload?.statusCode !== 200 || false,
        payLaterEligibilityApiLoader: false,
      };
    case 'PAY_LATER_ELIGIBILITY_LOADER':
      return {
        ...state,
        payLaterEligibilityApiLoader: payload,
      };
    case 'CART_DETAILS_LOADER':
      return {
        ...state,
      };
    case 'CART_DETAILS':
      return {
        ...state,
        cartDetailsLoader: false,
        cartDetails: payload,
        cartTotal: payload?.cartAmount + payload?.shippingCharge || 0,
        paymentAbortController: payload?.paymentAbortController ? payload?.paymentAbortController : {},
      };
    case 'GIFTCARD_REMOVE':
      return {
        ...state,
        giftCards: payload === 'ALL' ? [] : state.giftCards.filter((giftCard) => giftCard.number !== payload),
      };

    case 'GIFTCARD_UPDATE':
      return {
        ...state,
        giftCards: payload.giftCards,
        giftCardPaymentTotal: payload.giftCardPaymentTotal,
      };

    case 'RAZORPAY_PAYMENT_UPDATE':
      return {
        ...state,
        razorpayPaymentTotal: payload.razorpayPaymentTotal,
        disablePaymentConfirmationModal: payload.disablePaymentConfirmationModal,
        isRazorPayPaymentDisabled: payload.razorpayPaymentTotal < 1,
      };

    case 'PAYMENT_SUMMARY_DETAILS':
      return {
        ...state,
        paymentSummaryDetails: payload?.paymentSummaryDetails || {},
        paymentSummaryDetailsLoader: payload?.paymentSummaryDetailsLoader || false,
      };
    case 'PAYMENT_SUMMARY_DETAILS_LOADER':
      return {
        ...state,
        paymentSummaryDetailsLoader: payload,
      };
    case 'PAYMENT_SUMMARY_PAGE_UNMOUNT':
      return {
        ...state,
        paymentSummaryDetails: {},
        paymentSummaryDetailsLoader: true,
      };
    case 'PAYMENT_PAGE_UNMOUNT':
      return {
        ...state,
        paymentPageLoader: true,
        razorpayKey: '',
        customerTokens: [],
        customerId: '',
        contact: '',
        email: '',
        status: false,
        isGooglePayIntentAvailable: false,
        isEligibleForCRED: false,
        CREDEligibilityDescription: '',
        availableIntentApps: [],
        razorpayOptions: {},
        razorpayPaymentTotal: 0,
        isRazorPayPaymentDisabled: true,
        giftCards: [],
        giftCardPaymentTotal: 0,
        paymentDowntime: {},
        payLaterEligibilityApiLoader: false,
        paymentConfirmationModalToggle: false,
        disablePaymentConfirmationModal: false,
      };

    case 'PAYMENT_CONFIRMATION_MODAL_TOGGLE': {
      return {
        ...state,
        paymentConfirmationModalToggle: payload,
      };
    }

    case 'SELECTED_PAYMENT_METHOD': {
      return {
        ...state,
        selectedPaymentMethod: payload,
      };
    }
    case 'SET_ACTIVE_PAYMENT_OPTION': {
      return {
        ...state,
        activePaymentOption: payload,
      };
    }
    case 'SEQUENTIAL_PAYMENT_INIT_ACTION': {
      return {
        ...state,
        // Payment Abort Controller when user leave the page all the api will be cancelled
        paymentAbortController: payload?.hasOwnProperty('paymentAbortController')
          ? payload?.paymentAbortController
          : state.paymentAbortController,

        // Customer API
        customerTokens: payload?.hasOwnProperty('customerApiResponse')
          ? payload?.customerApiResponse?.payment?.customerTokens
          : state?.customerTokens,

        customerId: payload?.hasOwnProperty('customerApiResponse')
          ? payload?.customerApiResponse?.payment?.customerId
          : state?.customerId,

        // Seller API
        razorpayKey: payload?.hasOwnProperty('sellerApiResponse')
          ? payload?.sellerApiResponse?.sellerDetails?.clientId
          : state?.razorpayKey,

        // cart api response
        cartDetails: payload?.hasOwnProperty('cartApiResponse') ? payload?.cartApiResponse : state?.cartDetails,
        cartTotal: payload?.hasOwnProperty('cartApiResponse')
          ? payload?.cartApiResponse?.cartAmount + payload?.cartApiResponse?.shippingCharge
          : state?.cartTotal,

        // Common payment data
        email: payload?.hasOwnProperty('email') ? payload?.email : state?.email,
        contact: payload?.hasOwnProperty('contact') ? payload?.contact : state?.contact,

        // Intent APP for Mobile APP
        availableIntentApps: payload?.hasOwnProperty('availableIntentApps')
          ? payload?.availableIntentApps
          : state?.availableIntentApps,

        // Intent APP for MWeb APP
        isGooglePayIntentAvailable: payload?.hasOwnProperty('isGooglePayIntentAvailable')
          ? payload?.isGooglePayIntentAvailable
          : state?.isGooglePayIntentAvailable,

        // CRED eligibility check
        isEligibleForCRED: payload?.hasOwnProperty('credEligibility')
          ? payload?.credEligibility?.status
          : state?.isEligibleForCRED || false,
        CREDEligibilityDescription: payload?.hasOwnProperty('credEligibility')
          ? payload?.credEligibility?.description
          : state?.CREDEligibilityDescription || 'Pay using your saved credit cards for instant checkout',

        // Razorpay Options
        razorpayOptions: payload?.hasOwnProperty('razorpayOptions')
          ? payload?.razorpayOptions
          : state?.razorpayOptions || {},

        // API Loader
        sequentialPaymentInitApis: {
          isCartApiLoading: payload?.hasOwnProperty('isCartApiLoading')
            ? payload?.isCartApiLoading
            : state?.sequentialPaymentInitApis?.isCartApiLoading,

          isCustomerApiLoading: payload?.hasOwnProperty('isCustomerApiLoading')
            ? payload?.isCustomerApiLoading
            : state?.sequentialPaymentInitApis?.isCustomerApiLoading,

          isWalletApiLoading: payload?.hasOwnProperty('isWalletApiLoading')
            ? payload?.isWalletApiLoading
            : state?.sequentialPaymentInitApis?.isWalletApiLoading,

          isPaymentApiLoading: payload?.hasOwnProperty('isPaymentApiLoading')
            ? payload?.isPaymentApiLoading
            : state?.sequentialPaymentInitApis?.isPaymentApiLoading,

          isRazorpayOptionLoading: payload?.hasOwnProperty('isRazorpayOptionLoading')
            ? payload?.isRazorpayOptionLoading
            : state?.sequentialPaymentInitApis?.isRazorpayOptionLoading,
        },
      };
    }
    default:
      return state;
  }
};

export default reducer;
