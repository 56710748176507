/**
 * Logs a Google Analytics event for error tracking
 *
 * @param { number } statusCode - The HTTP status code of the API failure.
 * @param { string } errorCode - The error code returned by the API.
 * @param { string } errorMessage - The error message returned by the API.
 * @param { string } type - The source or category of the error (e.g., wallet, payment, ...)
 */

export const GAEvent_ErrorTracking = (statusCode: number, errorCode: string, errorMessage: string, type: string) => {
  try {
    const errorName =
      errorMessage?.length > 100
        ? `${errorCode}-${statusCode}|${type}`
        : `${errorMessage}-${errorCode}-${statusCode}|${type}`;
    window.dataLayer.push({
      event: 'error_shown',
      error_name: errorName,
    });
  } catch {}
};
