import {
  getCookie as _getCookie,
  getCookies as _getCookies,
  setCookie as _setCookie,
  deleteCookie as _deleteCookie,
} from 'cookies-next';

export const setCookie = (name, value, options = {}) => {
  _setCookie(name, value, {
    path: '/',
    httpOnly: false,
    secure: true,
    sameSite: 'Strict',
    ...options,
  });
};

export const removeCookie = (name, options = {}) => {
  _deleteCookie(name, {
    path: '/',
    httpOnly: false,
    secure: true,
    sameSite: 'Strict',
    ...options,
  });
};

export const getCookie = (name, { req, res } = {}) => {
  return _getCookie(name, { req, res });
};

export const getCookies = ({ req, res } = {}) => {
  const cookies = _getCookies({ req, res });
  if (!req) {
    Object.keys(cookies).forEach((key) => {
      cookies[key] = decodeURIComponent(cookies[key]);
    });
  }
  return cookies;
};
