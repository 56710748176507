const initialState = {
  type: 'toast', // toast | popup
  appearance: 'success',
  status: false,
  heading: '',
  message: '',
  timeout: 0,
  classname: '',
  leftButtons: {
    status: false,
    text: '',
    classname: '',
    redirect: false,
    onClick: () => {},
  },
  rightButtons: {
    status: false,
    text: '',
    classname: '',
    redirect: false,
    onClick: () => {},
  },
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case 'SHOW_TOAST_MESSAGE':
      return {
        ...state,
        type: payload?.type || 'toast',
        appearance: payload?.appearance || '',
        status: payload?.status || false,
        heading: payload?.heading || '',
        message: payload?.message || '',
        timeout: payload?.timeout || 0,
        classname: payload?.classname || '',
        leftButtons: payload?.leftButtons || {
          status: false,
          text: '',
          classname: '',
          redirect: false,
          onClick: () => {},
        },
        rightButtons: payload?.rightButtons || {
          status: false,
          text: '',
          classname: '',
          redirect: false,
          onClick: () => {},
        },
      };
    case 'HIDE_TOAST_MESSAGE':
      return {
        ...state,
        type: 'toast',
        appearance: '',
        status: false,
        heading: '',
        message: '',
        timeout: 0,
        classname: '',
        leftButtons: {
          status: false,
          text: '',
          classname: '',
          redirect: false,
          onClick: () => {},
        },
        rightButtons: {
          status: false,
          text: '',
          classname: '',
          redirect: false,
          onClick: () => {},
        },
      };
    default:
      return state;
  }
};

export default reducer;
