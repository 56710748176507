const contentfulConstant = {
  indexPageContent: {
    type: 'page',
    key: 'fields.slug',
    field: 'index',
  },
  promiseSectionContent: {
    type: 'promiseSection',
    key: 'fields.promiseSlug',
    field: 'ourProductPromise',
  },
  modelCardPimContent: {
    type: 'modelCardPim',
    key: 'fields.modelcode',
    select: 'fields.pimResponse',
  },
  modelCardContent: {
    type: 'modelCard',
    key: 'fields.modelcode',
    select:
      'fields.promiseDecatabs,fields.sizeTemplate,fields.frequentlyBrought,fields.questionAnswers,fields.promotion,fields.modelcode,fields.catSlug,fields.productSlug,fields.imageMediaUrl,fields.productName,fields.sellingPrice',
  },
  megaMenuContent: {
    type: 'megaMenu',
    key: 'fields.slug',
    field: 'web',
  },
  newMegaMenuContent: {
    type: 'content',
    key: 'fields.key',
    field: 'navigation',
  },
  categoryPageContent: {
    type: 'categoryPage',
    key: 'fields.categoryId',
  },
  pageContent: {
    type: 'page',
    key: 'fields.slug',
    select: 'sys.id,fields.slug,fields.metaTitle',
  },
  deliveryTermsContent: {
    type: 'heavyText',
    key: 'fields.pageSlug',
    field: 'deacthlon-terms-and-condition',
  },
  footerContent: {
    type: 'footer',
    key: 'fields.slug',
    field: 'footer',
    fieldShopinshop: 'shopinshop',
  },
  pageCMSContent: {
    type: 'epCreater',
    key: 'fields.slug',
  },
  storePageContent: {
    type: 'storePage',
    key: 'fields.slug',
  },
  storeListContent: {
    type: 'storeList',
    key: 'fields.name',
    field: 'Store List',
  },
  appSettingContent: {
    type: 'appSettings',
    key: 'fields.key[in]',
    select: 'fields',
  },
  bannerCardContent: {
    type: 'bannerCard',
    key: 'fields.slug[in]',
  },
  sellerInformationContent: {
    type: 'sellerInformation',
    key: 'fields.sellerId',
  },
  repexThankYouScreenContent: {
    type: 'appSettings',
    key: 'fields.key[in]',
    field: 'exchange_thank_you',
  },
  categoryDefaultBanner: {
    type: 'categoryDefaultBanner',
  },
  walletSectionContent: {
    type: 'newWallet',
  },
};

module.exports = contentfulConstant;
