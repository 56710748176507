import fetchApi from '@/src/Middleware/fetchApi';

export default async function getProductStockAMI(itemIds, productData) {
  const selectedArticle = productData?.articles?.[0];

  const response = await fetchApi(null, `/api/product/stocks`, {
    data: {
      itemIds,
      items: [
        {
          additionalPackagingWeight: productData?.additionalWeight || 0,
          additionalShippingFee: productData?.additionalShippingFee || 0,
          ean: 'string',
          // ean: selectedArticle?.ean || 'string',
          itemDimension: {
            height: 1,
            length: 1,
            width: 1,
          },
          itemId: selectedArticle?.skuArticleId,
          itemName: productData?.description?.productName || 'string',
          itemPrice: selectedArticle?.priceForFront?.finalPrice,
          itemQty: 1,
          itemWeight: selectedArticle?.weight?.weight || 1,
          type: 'product',
        },
      ],
      isDecathlon: false,
    },
  });
  return response.status ? response?.itemsStockPicture : [];
}
