const initialState = {
  skeletonLoader: false,
  loader: true,
  count: 0,
  productsInStock: {
    pim: [],
    wcms: [],
  },
  productsOutOfStock: {
    pim: [],
    wcms: [],
  },
  page: 0,
  pageCount: 0,
};

export function wishlist(state = initialState, action) {
  switch (action.type) {
    case 'WISHLIST_FETCHED': {
      return {
        ...state,
        productsInStock: {
          pim: state?.productsInStock?.pim?.length
            ? [...state?.productsInStock?.pim, ...action?.payload?.productsInStock?.pim]
            : action?.payload?.productsInStock?.pim,
          wcms: state?.productsInStock?.wcms?.length
            ? [...state?.productsInStock?.wcms, ...action?.payload?.productsInStock?.wcms]
            : action?.payload?.productsInStock?.wcms,
        },
        productsOutOfStock: {
          pim: state?.productsOutOfStock?.pim?.length
            ? [...state?.productsOutOfStock?.pim, ...action?.payload?.productsOutOfStock?.pim]
            : action?.payload?.productsOutOfStock?.pim,
          wcms: state?.productsOutOfStock?.wcms?.length
            ? [...state?.productsOutOfStock?.wcms, ...action?.payload?.productsOutOfStock?.wcms]
            : action?.payload?.productsOutOfStock?.wcms,
        },
        pageCount: action?.payload?.pageCount,
      };
    }
    case 'WISHLIST_UPDATED_AFTER_DELETE_ITEM': {
      return {
        ...state,
        productsInStock: {
          pim: action?.payload?.pim,
          wcms: action?.payload?.wcms,
        },
      };
    }

    case 'TOGGLE_WISHLIST_LOADING':
      return {
        ...state,
        loader: action?.payload,
      };

    case 'TOGGLE_SKELETON_LOADING':
      return {
        ...state,
        skeletonLoader: action?.payload,
      };

    case 'PAGE_COUNT':
      return {
        ...state,
        pageCount: action?.payload,
      };

    case 'PAGE_NUMBER':
      return {
        ...state,
        page: action?.payload,
      };

    case 'PRODUCT_COUNT':
      return {
        ...state,
        count: action?.payload,
      };

    case 'WISHLIST_UNMOUNT':
      return {
        ...state,
        skeletonLoader: true,
        loader: false,
        count: 0,
        productsInStock: [],
        productsOutOfStock: [],
        page: 0,
        pageCount: 0,
        selectedProduct: null,
      };

    case 'OUTOFSTOCK_CLEAR':
      return {
        ...state,
        productsOutOfStock: {
          pim: [],
          wcms: [],
        },
      };

    default:
      return state;
  }
}
