import {
  WALLET_FETCHED,
  REWARDS_FETCHED,
  WALLET_EXPIRED_FETCHED,
  WALLET_CREATED,
  WALLET_BALANCE_FETCHED,
  WALLET_CONTENTFUL_FETCHED,
  UPDATE_HISTORY_PAGE,
  UPDATE_EXPIRY_PAGE,
  TOGGLE_WALLET_USE_IN_PAYMENT,
  WALLET_ALL_TRANSACTION_TOGGLE,
  TOGGLE_GC_MODAL,
  TOGGLE_WALLET_BALANCE_ERROR_MODAL,
  WALLET_CONTENTFUL_LOADING,
  WALLET_BALANCE_LOADING,
  TRANSACTION_LOADING_ERROR,
  WALLET_BALANCE_LOADING_ERROR,
  TRANSACTION_LOADING,
  EXPIRED_TRANSACTION_LOADING,
  GC_LOADING,
  WALLET_FEATURE_FLAG,
  REWARDS_BALANCE_FETCHED,
  REWARDS_BALANCE_LOADING_ERROR,
  UPDATE_REWARDS_HISTORY_PAGE,
} from '../actions/WalletPage';

const initialState = {
  title: '',
  subTitle: '',
  benefits: [],
  notes: [],
  termsAndConditions: [],
  faqs: [],
  walletId: null,
  balanceAmount: 0,
  rewardsBalance: 0,
  transactions: [],
  expiredTransactions: [],
  isLoadingContentful: true,
  isLoadingBalance: true,
  historyPageNumber: 0,
  expiryPageNumber: 0,
  totalHistoryCount: 0,
  totalExpiryCount: 0,
  isAllTransactionsScreenOpen: false,
  isAllTransactionsLoading: true,
  isExpiryTransactionsLoading: true,
  isAddGiftcardToWalletScreenOpen: false,
  isAddGiftcardToWalletLoading: false,
  isTransactionLoadingError: false,
  walletBalanceLoadingError: false,
  rewardsBalanceLoadingError: false,
  isWalletBalanceErrorModalOpen: false,
  useWalletBalanceInPayment: false,
  isGCAddLoading: false,
  walletFeatureFlag: true,
  walletFeatureFlagErrorMessage: null,
  totalRewardsHistoryCount: 0,
  rewardsTransactions: [],
  rewardsHistoryPageNumber: 0,
};

export default function wallet(state = initialState, action) {
  switch (action.type) {
    case WALLET_FETCHED: {
      const { transactions = [], walletId = null, count = 0, pageIndex = 0 } = action?.payload ?? {};
      return {
        ...state,
        transactions:
          (state?.transactions?.length ?? 0) && pageIndex > 0
            ? [...(state?.transactions ?? []), ...transactions]
            : transactions,
        totalHistoryCount: pageIndex === 0 ? 10 : state?.totalHistoryCount + 10,
        walletId,
        isAllTransactionsLoading: false,
      };
    }

    case REWARDS_FETCHED: {
      const { transactions = [], walletId = null, count = 0, pageIndex = 0 } = action?.payload ?? {};
      return {
        ...state,
        rewardsTransactions:
          (state?.rewardsTransactions?.length ?? 0) && pageIndex > 0
            ? [...(state?.rewardsTransactions ?? []), ...transactions]
            : transactions,
        totalRewardsHistoryCount: pageIndex === 0 ? 10 : state?.totalRewardsHistoryCount + 10,
        walletId,
        isAllTransactionsLoading: false,
      };
    }

    case WALLET_EXPIRED_FETCHED: {
      const { transactions = [], walletId = null, count = 0, pageIndex = 0 } = action?.payload ?? {};
      return {
        ...state,
        expiredTransactions:
          (state?.transactions?.length ?? 0) && pageIndex > 0
            ? [...(state?.expiredTransactions ?? []), ...transactions]
            : transactions,
        totalExpiryCount: state?.totalExpiryCount + 10,
        walletId,
        isExpiryTransactionsLoading: false,
      };
    }

    case WALLET_CREATED: {
      const { walletId = null } = action?.payload ?? {};
      return {
        ...state,
        walletId,
      };
    }

    case WALLET_BALANCE_FETCHED: {
      const { balance = 0 } = action?.payload ?? {};
      return {
        ...state,
        balanceAmount: balance,
        useWalletBalanceInPayment: balance > 0,
        walletFeatureFlag: true,
        walletBalanceLoadingError: false,
        isWalletBalanceErrorModalOpen: false,
        isLoadingBalance: false,
      };
    }

    case REWARDS_BALANCE_FETCHED: {
      const { balance = 0 } = action?.payload ?? {};
      return {
        ...state,
        rewardsBalance: balance,
        walletFeatureFlag: true,
        walletBalanceLoadingError: false,
        isWalletBalanceErrorModalOpen: false,
        isLoadingBalance: false,
      };
    }

    case WALLET_CONTENTFUL_FETCHED: {
      const {
        benefits = [],
        notes = [],
        faqs = [],
        termsAndConditions = [],
        title = null,
        subTitle = null,
      } = action?.payload ?? {};
      return {
        ...state,
        title,
        subTitle,
        benefits,
        notes,
        faqs,
        termsAndConditions,
      };
    }

    case UPDATE_HISTORY_PAGE: {
      return {
        ...state,
        historyPageNumber: action?.payload + 10,
      };
    }

    case UPDATE_REWARDS_HISTORY_PAGE: {
      return {
        ...state,
        rewardsHistoryPageNumber: action?.payload + 10,
      };
    }

    case UPDATE_EXPIRY_PAGE: {
      return {
        ...state,
        expiryPageNumber: action?.payload + 10,
      };
    }

    // modal toggle cases
    case TOGGLE_WALLET_USE_IN_PAYMENT: {
      return {
        ...state,
        useWalletBalanceInPayment: action?.payload?.status,
      };
    }

    case WALLET_ALL_TRANSACTION_TOGGLE: {
      return {
        ...state,
        isAllTransactionsScreenOpen: action?.payload,
      };
    }

    case TOGGLE_GC_MODAL: {
      return {
        ...state,
        isAddGiftcardToWalletScreenOpen: !state?.isAddGiftcardToWalletScreenOpen,
      };
    }

    case TOGGLE_WALLET_BALANCE_ERROR_MODAL: {
      return {
        ...state,
        isWalletBalanceErrorModalOpen: action?.payload,
      };
    }

    case WALLET_CONTENTFUL_LOADING: {
      return {
        ...state,
        isLoadingContentful: action?.payload,
      };
    }

    case WALLET_BALANCE_LOADING: {
      return {
        ...state,
        isLoadingBalance: action?.payload,
      };
    }

    case TRANSACTION_LOADING_ERROR: {
      return {
        ...state,
        isTransactionLoadingError: action?.payload,
      };
    }

    case WALLET_BALANCE_LOADING_ERROR: {
      return {
        ...state,
        walletBalanceLoadingError: action?.payload,
        isLoadingBalance: false,
      };
    }

    case REWARDS_BALANCE_LOADING_ERROR: {
      return {
        ...state,
        rewardsBalanceLoadingError: action?.payload,
        isLoadingBalance: false,
      };
    }

    case TRANSACTION_LOADING: {
      return {
        ...state,
        isAllTransactionsLoading: action?.payload,
      };
    }

    case EXPIRED_TRANSACTION_LOADING: {
      return {
        ...state,
        isExpiryTransactionsLoading: action?.payload,
      };
    }

    case GC_LOADING: {
      return {
        ...state,
        isGCAddLoading: action?.payload,
      };
    }

    case WALLET_FEATURE_FLAG: {
      return {
        ...state,
        walletFeatureFlag: action?.payload?.enabled,
        walletFeatureFlagErrorMessage: action?.payload?.message,
      };
    }

    default:
      return state;
  }
}
