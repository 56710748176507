export const secureApiRoutes = [
  // checkout apis

  '/api/logout',
  '/api/dktlogin/get-user-details',
  '/api/dktlogin/update-user-details',
  '/api/customer/customeraddress',
  '/api/customer/addcustomeraddress',
  '/api/customer/updatecustomeraddress',
  '/api/customer/deletecustomeraddress',
  '/api/customer/createdefaultaddress',
  '/api/customer/digitalorders',
  '/api/customer/storeorders',
  '/api/customer/getCancelReasons',
  '/api/customer/cancel_replacement_serviceability',
  '/api/customer/create_cancel_replacement',
  '/api/customer/repexFulfilmentOptions',
  '/api/customer/getTrackDetails',
  '/api/customer/getTrackDetails-repex',
  '/api/customer/createreturn',
  '/api/customer/createreturn-ami',
  '/api/customer/get-refund-modes',
  '/api/customer/createFundAccount',
  '/api/customer/order',
  '/api/customer/orderCancel',
  '/api/customer/get-return-details-ami',
  '/api/deliveryFeedback/deliveryFeedback',
  '/api/cart/merge',
  '/api/checkout/delivery-address',
  '/api/checkout/add-address',
  '/api/checkout/delivery-options',
  '/api/checkout/add-options',
  '/api/checkout/payment-init',
  '/api/checkout/payment-downtime',
  '/api/checkout/bin-series',
  '/api/checkout/vpa-validation',
  '/api/checkout/create-payment',
  '/api/checkout/payment-status',
  '/api/checkout/deactivate-qr-code',
  '/api/payment/cart',
  '/api/payment/create',
  '/api/payment/deactivate-qr-code',
  '/api/payment/downtime',
  '/api/payment/iin',
  '/api/payment/init',
  '/api/payment/status',
  '/api/payment/summary',
  '/api/payment/vpa',
  '/api/payment/customer',
  '/api/payment/seller',
  '/api/payment/wallet',
  '/api/payment/gift-card-validation',
  '/api/payment/qwikcilver/authorize',
  '/api/product/replacementExchange-enable',
  '/api/product/exchange-serviceability',
  '/api/product/replacement-orderCancel',
  '/api/product/cancel-return',
  '/api/product/exchange-reasons',
  '/api/product/replacement-summary',
  '/api/product/replaceability-check',
  '/api/product/similar-products',
  '/api/product/create-replacement',
  '/api/product/create-exchange',
  '/api/wishlist/add',
  '/api/wishlist',
  '/api/wishlist/delete',
  '/api/wishlist/delete-all',
  '/api/wishlist/fetch-product-data',
  '/api/wishlist/move',
  '/api/orders/invoice',
  '/api/payment/paylater-eligibility',
  '/api/recently-view',
  '/api/wallet',
  '/api/wallet/load',
  '/api/wallet/create',
  '/api/wallet/balance',
  '/api/employee-discount/employee-discount-details',
];

export const partiallySecureApiRoutes = [
  // '/api/get-fedid-token',
  '/api/perso-reco',
  '/api/product/notify-me',
  '/api/cart',
  '/api/cart/add',
  '/api/cart/count',
  '/api/cart/delete',
  '/api/cart/editupdatecart',
  '/api/cart/move-to-cart',
  '/api/cart/update',
  '/api/cart/updatecartaddress',
  '/api/cart/updatecartpincode',
  '/api/cart/coupon/apply',
  '/api/cart/coupon/remove',
  '/api/cart-items/delete',
  '/api/cart-items/update',
  '/api/cart-items/updateSelection',
];

export const decathlonAppApiRoutes = [
  '/api/checkout/payment-init',
  '/api/checkout/payment-downtime',
  '/api/checkout/bin-series',
  '/api/checkout/vpa-validation',
  '/api/checkout/create-payment',
  '/api/checkout/payment-status',
  '/api/payment/cart',
  '/api/payment/create',
  '/api/payment/deactivate-qr-code',
  '/api/payment/downtime',
  '/api/payment/iin',
  '/api/payment/init',
  '/api/payment/status',
  '/api/payment/vpa',
  '/api/payment/gift-card-validation',
  '/api/payment/paylater-eligibility',
  '/api/payment/customer',
  '/api/payment/seller',
  '/api/payment/wallet',
  '/api/wallet/create',
  '/api/wallet/balance',
  '/api/wallet/load',
];

// export const decathlonAppRZPRedirectApiRoute = '/api/checkout/payment-status';
export const decathlonAppRZPRedirectApiRoute = '/api/payment/status';
