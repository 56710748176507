/* eslint-disable camelcase */
import axios from 'axios';
import { setCookie } from './Cookies';
import { encrypt } from '../Middleware/helpers';
import { baseurlQwikcilverApis } from '@/constant/apiConstants';
export const getQwikcilverAccessToken = async (req, res) => {
  let response = {};

  const date = new Date();
  date.setMinutes(date.getMinutes() - 5);

  try {
    await axios
      .post(
        baseurlQwikcilverApis.V3.authorize,
        {
          UserName: process.env.QWIKCILVER_USERNAME,
          Password: process.env.QWIKCILVER_PASSWORD,
          ForwardingEntityId: process.env.QWIKCILVER_FORWARDING_ENTITY_ID,
          ForwardingEntityPassword: process.env.QWIKCILVER_FORWARDING_ENTITY_PASSWORD,
          TransactionId: date.getTime(),
          TerminalId: process.env.QWIKCILVER_TERMINAL_ID,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            DateatClient: date.toLocaleTimeString(),
          },
        },
      )
      .then((resp) => {
        response = {
          status: !!resp?.data?.AuthToken,
          token: resp?.data?.AuthToken,
        };

        if (response?.status) {
          setCookie('qwikcilverToken', encrypt(response?.token), {
            req,
            res,
          });
        }
      })
      .catch((error) => {
        return {
          error,
          status: false,
          statusCode: 502,
          qwikcilver: error?.response?.data || 'error',
        };
      });
  } catch (error) {
    response = { error };
  }

  return response;
};
