import { createStore, applyMiddleware } from 'redux';
import { HYDRATE, createWrapper } from 'next-redux-wrapper';
import { thunk } from 'redux-thunk';
import rootReducer from './reducers';

const bindMiddleware = (middleware) => {
  // if (process.env.NEXT_PUBLIC_ENVIRONMENT !== 'PRODUCTION') {
  //   const { composeWithDevTools } = require('redux-devtools-extension');
  //   return composeWithDevTools(applyMiddleware(...middleware));
  // }

  return applyMiddleware(...middleware);
};

const reducer = (state, action) => {
  if (action.type === HYDRATE) {
    const nextState = {
      ...state,
      ...action.payload,
    };
    return nextState;
  } else {
    // whenever we deal with static rendering or client side rendering, this will be the case
    // reducers is the combinedReducers
    return rootReducer(state, action);
  }
};

export const initStore = () => {
  return createStore(reducer, bindMiddleware([thunk]));
};

export default createWrapper(initStore, {
  serializeState: (state) => (typeof state === 'object' ? JSON.stringify(state) : state),
  deserializeState: (state) => (typeof state === 'string' ? JSON.parse(state) : state),
});
