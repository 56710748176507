import { combineReducers } from 'redux';
import reducer from './reducer';
import paymentReducer from './paymentReducer';
import navigationReducer from './navigationReducer';
import toastMessageReducer from './toastMessageReducer';
import { wishlist as wishlistReducer } from './wishlistReducer';
import addItemToCartReducer from './addItemToCartReducer';
import wallet from './walletReducer';
import dynamicYieldReducer from './dynamicYieldReducer';

const rootReducer = combineReducers({
  reducer,
  paymentReducer,
  toastMessageReducer,
  addItemToCartReducer,
  wishlistReducer,
  wallet,
  navigationReducer,
  dynamicYieldReducer,
});

export default rootReducer;
