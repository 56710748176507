import { getContentfulClientEntries } from '../Utils';

export default async function () {
  const promiseSection = await getContentfulClientEntries({
    content_type: 'content',
    include: 7,
    'fields.key': 'app_redirection_URLs',
  });

  const appURLs =
    promiseSection?.items?.[0]?.fields?.contents?.map((item) => {
      if (item?.fields?.key === 'app_redirection_URLs_Android') {
        return { android: item?.fields?.hyperlinkUrl };
      }

      if (item?.fields?.key === 'app_redirection_URLs_iOS') {
        return { ios: item?.fields?.hyperlinkUrl };
      }
    }) || [];

  return Object.assign({}, ...appURLs);
}
