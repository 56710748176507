import { footerContent } from '../../../constant/contentfulConstants';
import { getContentfulClientEntries } from '../../Utils';
import { storeFooterWCMSData } from '../../../redux/actions/actions';

export default async function fetchFooter({ pathname, platform, res }, store) {
  if (
    typeof window === 'undefined' &&
    !(process.env.NEXT_PUBLIC_FOOTER_EXCLUDE_PATH.indexOf(pathname.replace('/', '')) >= 1)
  ) {
    const footerContentData = await getContentfulClientEntries(
      {
        content_type: footerContent.type,
        include: 7,
        [footerContent.key]: platform === 'PAYTM_MINI' ? footerContent.fieldShopinshop : footerContent.field,
      },
      res,
    );
    const footerItems = footerContentData?.items?.length ? footerContentData.items[0].fields : {};
    const footer = {
      chatBotImage: footerItems?.chatBotImage,
      chatBotTime: footerItems?.chatBotTime,
      chatBotTrigger: footerItems?.chatBotTrigger,
      chatBotDelayTimer: footerItems?.chatBotDelayTimer,
      chatBotLogInHeadText: footerItems?.chatBotLogInHeadText,
      chatBotLogInText: footerItems?.chatBotLogInText,
      chatBotLogOutHeadText: footerItems?.chatBotLogOutHeadText || 'Hi There',
      chatBotLogOutText: footerItems?.chatBotLogOutText || 'Please click here to sign in & chat with me :)',
      footerPromise: {
        title:
          `${footerItems?.footerPromise?.fields?.smallTitle}` + ` ` + `${footerItems?.footerPromise?.fields?.bigTitle}`,
        promiseCards: footerItems?.footerPromise?.fields?.ourPromiseCards?.map((card) => ({
          imageHyperLink: card?.fields?.imageHyperLink,
          targetUrl: card?.fields?.targetUrl,
          imageMediaUrl: card?.fields?.imageMediaUrl,
          text: card?.fields?.text,
        })),
      },
      support: footerItems.support,
      supportLinks: footerItems.supportLinks?.map((link) => ({
        text: link?.fields?.text,
        url: link?.fields?.textHyperLink,
      })),
      ourServices: footerItems.ourServices,
      ourServicesLink: footerItems.ourServicesLink?.map((link) => ({
        text: link?.fields?.text,
        url: link?.fields?.textHyperLink,
      })),
      aboutUs: footerItems.aboutUs,
      aboutUsLinks: footerItems.aboutUsLinks?.map((link) => ({
        text: link?.fields?.text,
        url: link?.fields?.textHyperLink,
      })),

      legal: footerItems.legal,
      legalLinks: footerItems.legalLinks?.map((link) => ({
        text: link?.fields?.text,
        url: link?.fields?.textHyperLink,
      })),
      footerLinks: footerItems?.footerLinks?.map((list) => ({
        title: list?.fields?.footerPageHeading?.fields?.descriptionText || '',
        links: list?.fields?.footerPageLink?.map((link) => ({
          text: link?.fields?.text,
          url: link?.fields?.textHyperLink,
        })),
      })),
      countries: footerItems.footerCountries?.map((link) => ({
        url: link?.fields?.textHyperLink,
        text: link?.fields?.text,
      })),
      purposeGreen: footerItems?.footerPurposeGreen,
      purposeBlue: footerItems?.footerPurposeBlue,
      copyrights: footerItems?.footerCopyrights,
    };
    store.dispatch(storeFooterWCMSData(footer));
  }
  return {};
}
