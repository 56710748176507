/* eslint-disable camelcase */
/* eslint-disable no-else-return */
/* eslint-disable no-console */
/* eslint-disable no-return-await */
import fetchApi from '@/src/Middleware/fetchApi';
import LocationPrompt from './LocationPrompt';
import { getCookie, getCookies, setCookie } from '../../src/Utils/Cookies';

export const addToCart = ({ cartItems, addToCartReco }) => {
  const { clickId } = getCookies('clickId');
  clickId &&
    cartItems.forEach((item) => {
      if (item.isDecathlon) item.clickId = clickId;
    });
  return async (dispatch) => {
    const apiResponse = await fetchApi(null, `/api/cart/add`, {
      data: { cartItems },
    });

    if (apiResponse?.status) {
      if (apiResponse?.cart?.errorDetails?.errorCode) {
        const errorCode = apiResponse?.cart?.errorDetails?.errorCode;
        if (errorCode === 'C002') {
          return { ...apiResponse, status: false };
        }
      }

      dispatch({
        type: 'ADD_TO_CART_POPUP',
        payload: addToCartReco,
      });

      dispatch({
        type: 'ADD_TO_CART',
        payload: {
          cartId: apiResponse?.cart?.cartId,
          cartLine: apiResponse?.cart?.cartLine,
        },
      });

      setCookie('dkt_cartId', apiResponse?.cart?.cartId);
    }

    return apiResponse;
  };
};
export const getCartCount = () => {
  return async (dispatch) => {
    const { dkt_cartId } = getCookies('dkt_cartId');
    if (dkt_cartId) {
      const apiResponse = await fetchApi(null, `/api/cart/count`);

      if (apiResponse?.status) {
        dispatch({
          type: 'GET_CART_COUNT',
          payload: apiResponse?.count || 0,
        });
      }

      return apiResponse;
    }
    return false;
  };
};

const getCart = ({ ctx, cartId, sessionId } = {}) => {
  const pincode = getCookie('dkt_pincode', { req: ctx?.req, res: ctx?.res });
  return async (dispatch) => {
    dispatch({
      type: 'TOGGLE_CART_LOADER',
      payload: true,
    });

    const apiResponse = await fetchApi(ctx, `/api/cart`, {
      cookies: sessionId ? `dkt_sessionId=${sessionId}; cartId=${cartId}` : false,
    });

    if (apiResponse?.status) {
      const { postalCode } = apiResponse.cart;
      dispatch({
        type: 'GET_CART',
        payload: apiResponse.cart,
      });

      if (Number(pincode) !== Number(postalCode)) {
        await dispatch(
          LocationPrompt.updatePincode({
            pincode: postalCode,
            ctx,
          }),
        );
      }
    }

    dispatch({
      type: 'TOGGLE_CART_LOADER',
      payload: false,
    });

    return apiResponse || {};
  };
};

export const updateCartPincode = ({ ctx, isCartPage, postalCode, isRewardsToggle }) => {
  return async (dispatch, getState) => {
    if (!isRewardsToggle) {
      dispatch({
        type: isCartPage ? 'TOGGLE_CART_LOADER' : 'TOGGLE_CHECKOUT_LOADER',
        payload: true,
      });
    }

    const {
      cartItems: { sportyRewardsStatus },
    } = getState()?.reducer?.cartPage;

    let isSportyRewardsEnabled;
    if (sportyRewardsStatus === undefined && isRewardsToggle === undefined && isCartPage) {
      isSportyRewardsEnabled = true;
    } else if (sportyRewardsStatus !== undefined && isRewardsToggle === undefined && isCartPage) {
      isSportyRewardsEnabled = sportyRewardsStatus;
    } else if (sportyRewardsStatus !== undefined && isRewardsToggle !== undefined && isCartPage) {
      isSportyRewardsEnabled = !sportyRewardsStatus;
    }

    const pinCode = postalCode ?? (getState()?.reducer.pincode || {});
    const apiResponse = await fetchApi(ctx, `/api/cart/updatecartpincode`, {
      data: {
        postalCode: pinCode,
        isSportyRewardsEnabled,
      },
    });

    if (apiResponse?.status) {
      dispatch({
        type: 'GET_CART',
        payload: apiResponse.cart,
      });
    }

    dispatch({
      type: isCartPage ? 'TOGGLE_CART_LOADER' : 'TOGGLE_CHECKOUT_LOADER',
      payload: false,
    });

    return apiResponse?.cart || {};
  };
};

const updateQuantity = ({ modelCode, itemCode, quantity }) => {
  return async (dispatch, getState) => {
    dispatch({ type: 'CART_UPDATING', payload: true });

    const {
      cartItems: { sportyRewardsStatus },
    } = getState()?.reducer?.cartPage;
    const apiResponse = await fetchApi(null, `/api/cart/update`, {
      data: { modelCode, itemCode, quantity, isSportyRewardsEnabled: sportyRewardsStatus },
    });

    if (apiResponse?.status) {
      dispatch({
        type: 'GET_CART',
        payload: apiResponse.cart,
      });
    }

    dispatch({ type: 'CART_UPDATING', payload: false });

    return apiResponse;
  };
};

const editCart = ({ cartItems, pickupLocationID = null, deliveryType = null, isSportyRewardsEnabled = true }) => {
  return async (dispatch) => {
    dispatch({
      type: 'TOGGLE_CART_LOADER',
      payload: true,
    });

    const apiResponse = await fetchApi(null, '/api/cart/editupdatecart', {
      data: {
        pickupLocationID: pickupLocationID || cartItems.pickupStoreDetails?.locationId,
        deliveryType,
        isSportyRewardsEnabled,
      },
    });

    if (apiResponse.status) {
      const { cartLines, postalCode } = apiResponse.cart;
      if (cartLines) {
        dispatch({
          type: 'GET_CART',
          payload: apiResponse.cart,
        });
      }
      if (Number(postalCode)) {
        await dispatch(
          LocationPrompt.updatePincode({
            pincode: postalCode,
            ctx: {},
          }),
        );
      }
    }
    dispatch({ type: 'TOGGLE_CART_LOADER', payload: false });
    return apiResponse;
  };
};

const deleteCartItem = ({ cartLineIds }) => {
  return async (dispatch, getState) => {
    dispatch({ type: 'CART_UPDATING', payload: true });

    const {
      cartItems: { sportyRewardsStatus },
    } = getState()?.reducer?.cartPage;

    const apiResponse = await fetchApi(null, '/api/cart-items/delete', {
      data: { cartLineIds, isSportyRewardsEnabled: sportyRewardsStatus },
    });

    if (apiResponse?.status) {
      dispatch({
        type: 'GET_CART',
        payload: apiResponse.cart,
      });
    }

    dispatch({ type: 'CART_UPDATING', payload: false });

    return apiResponse;
  };
};

const getCoupons = () => {
  return async (dispatch) => {
    const response = await fetchApi(null, `/api/cart/coupon`);
    if (response?.status && response?.coupons) {
      dispatch({
        type: 'GET_COUPONS',
        payload: response.coupons,
      });
    }
    return response;
  };
};

const applyCoupon = ({ couponCode }) => {
  return async (dispatch, getState) => {
    const {
      cartItems: { sportyRewardsStatus },
    } = getState()?.reducer?.cartPage;
    const response = await fetchApi(null, `/api/cart/coupon/apply`, {
      data: { couponCode, isSportyRewardsEnabled: sportyRewardsStatus },
    });
    if (response?.status && response?.cart) {
      dispatch({
        type: 'GET_CART',
        payload: response.cart,
      });
    }
    return response;
  };
};

const removeCoupon = () => {
  return async (dispatch) => {
    const response = await fetchApi(null, '/api/cart/coupon/remove');

    if (response?.status && response?.cart) {
      dispatch({
        type: 'GET_CART',
        payload: response.cart,
      });
    }
    return response;
  };
};

const moveToCart = (cartLineId) => {
  return async (dispatch) => {
    dispatch({ type: 'CART_UPDATING', payload: true });

    const apiResponse = await fetchApi(null, `/api/cart/move-to-cart`, {
      data: { cartLineId },
    });

    if (apiResponse?.status) {
      dispatch({
        type: 'GET_CART',
        payload: apiResponse.cart,
      });
    }

    dispatch({ type: 'CART_UPDATING', payload: false });

    return apiResponse;
  };
};

const clearCartCount = () => {
  return async (dispatch) => {
    dispatch({
      type: 'GET_CART_COUNT',
      payload: 0,
    });
  };
};

const toggleCartAddressList = () => {
  return async (dispatch) => {
    dispatch({
      type: 'TOGGLE_CART_ADDRESS_LIST',
    });
  };
};

const closeCartAddressList = () => {
  return async (dispatch) => {
    dispatch({
      type: 'CLOSE_CART_ADDRESS_LIST',
    });
  };
};

const updateCartAddress = ({ shippingAddress, billingAddress, showBillingOnly }) => {
  return async (dispatch) => {
    dispatch({
      type: 'TOGGLE_ADDRESS_LOADER',
      payload: true,
    });

    const addressRequests = [{ addressType: 'BILLING', id: billingAddress.address_id }];

    if (!showBillingOnly) {
      addressRequests.push({
        addressType: 'SHIPPING',
        id: shippingAddress.address_id,
      });
    }

    const apiResponse = await fetchApi(null, '/api/cart/updatecartaddress', {
      data: { addressRequests },
    });
    if (apiResponse.status) {
      const { cartLines, postalCode } = apiResponse.cart;

      if (cartLines) {
        dispatch({
          type: 'GET_CART',
          payload: apiResponse.cart,
        });
      }
      if (Number(postalCode)) {
        await dispatch(
          LocationPrompt.updatePincode({
            pincode: postalCode,
            ctx: {},
          }),
        );
      }
    }

    dispatch({
      type: 'TOGGLE_ADDRESS_LOADER',
      payload: false,
    });

    return apiResponse;
  };
};

const cartPage = {
  addToCart,
  getCartCount,
  getCart,
  updateQuantity,
  deleteCartItem,
  moveToCart,
  getCoupons,
  applyCoupon,
  removeCoupon,
  updateCartPincode,
  clearCartCount,
  editCart,
  toggleCartAddressList,
  closeCartAddressList,
  updateCartAddress,
};

export default cartPage;
