import { decrypt } from '../../Middleware/helpers';
import { deviceDetector, getPlatform } from '../../Utils';
import { getCookie, getCookies, setCookie } from '../../Utils/Cookies';

const AllPages = () => {
  let envWork;
  let pageType;
  let envTemplate;
  let sharedId;
  let userId;
  let userGender;
  let loyaltyId;
  let VarPageType;
  let pageCat0;

  const platform = getPlatform();
  const device = deviceDetector({ platform });
  const envChannel = device === 'desktop' ? 'WEB' : device.toUpperCase();
  if (window.location.href.includes('https://www.decathlon.in/')) {
    envWork = 'PROD';
  } else {
    envWork = 'PREPROD';
  }
  // Home page datalayer variables
  if (window.location.pathname === '/' && window.location.href.indexOf('query') === -1) {
    pageCat0 = 'HomePage';
    pageType = 'HomePage';
    envTemplate = 'HOME_PAGE';
  }
  // Product page datalayer variables
  else if (window.location.href.indexOf('/p') > 1 && window.location.href.indexOf('query') === -1) {
    VarPageType = 'Model';
    pageType = 'ProductPage';
    envTemplate = 'PRODUCT';
  }
  // cart page datalayer variables
  else if (window.location.href.indexOf('cart') > 1) {
    pageType = 'cart';
    envTemplate = 'BASKET';
  }
  // checkout page datalayer variables
  else if (window.location.href.indexOf('checkout') > 1) {
    pageType = 'ShoppingCart';
    envTemplate = 'CHECKOUT';
  }
  // Thank You page type
  else if (window.location.href.indexOf('thankyou') > 1) {
    pageType = 'Confirmation';
    envTemplate = 'CHECKOUT';
  }
  // Shop page type
  else if (window.location.href.indexOf('shop') > 1 && window.location.href.indexOf('query') === -1) {
    pageType = 'SportsPage';
    envTemplate = 'SHOP';
  }
  // search page type
  else if (window.location.href.indexOf('query') > 1) {
    pageType = 'Search';
    envTemplate = 'SEARCH RESULTS';
  }
  // Category page datalayer variables
  else if (
    window.location.href.indexOf('query') === -1 &&
    window.location.href.indexOf('thankyou') === -1 &&
    window.location.href.indexOf('myaccount') === -1
  ) {
    pageType = 'categorypage';
    envTemplate = 'CATEGORY';
  }

  // SharedID code

  const cookie = getCookies();
  const memberId = decrypt(cookie?.dkt_memberId);
  if (cookie && memberId) {
    sharedId = memberId;
    userId = memberId;
    const gender = decrypt(cookie?.dkt_ug);
    if (gender === 'female') {
      userGender = 'F';
    } else {
      userGender = 'M';
    }
    loyaltyId = decrypt(cookie.dkt_loyaltyCard);
  } else {
    sharedId = '';
    userId = '';
    userGender = '';
    loyaltyId = '';
  }
  // user agent
  const useragent = navigator.userAgent;
  window.dataLayer.push({
    event: 'AllPages',
    env_channel: envChannel,
    env_language: 'en',
    env_country: 'IN',
    order_currency: 'INR',
    env_work: envWork,
    page_type: pageType,
    env_template: envTemplate,
    env_dnt: 'disabled',
    page_cat0: pageCat0,
    varPageType: VarPageType,
    user_agent: useragent,
    shared_id: sharedId,
    user_gender: userGender,
    user_id: userId,
    loyalty_id: loyaltyId,
  });
  // Parse the URL
  const regex = /[?&]([^=#]+)=([^&#]*)/g;
  const url = window.location.href;
  const params = {};
  let match;
  while ((match = regex.exec(url))) {
    params[match[1]] = match[2];
  }

  const shoppingToolAction = localStorage.getItem('shoppingToolAction');
  const shoptool = shoppingToolAction && JSON.parse(String(shoppingToolAction));
  const st = shoptool && shoptool.st;
  // Shoppping tool Direct from google
  if (params.gclid !== undefined) {
    const gclid = { st: 'Direct', std: 'google-cpc', subVal: 'NA' };
    localStorage.setItem('shoppingToolAction', JSON.stringify(gclid));
  }
  // Shopping tool Direct from other utm_source
  else if (params.utm_source !== undefined) {
    const utm = {
      st: 'Direct',
      std: `${params.utm_medium} - ${params.utm_source} - ${params.utm_campaign}`,
      subVal: 'NA',
    };
    localStorage.setItem('shoppingToolAction', JSON.stringify(utm));
  } else if (params.icn !== undefined && st !== 'Banner') {
    const opeco = { st: 'Banner', std: params.icn, subVal: 'NA' };
    localStorage.setItem('shoppingToolAction', JSON.stringify(opeco));
  }
};

export default AllPages;
