export const showToastMessage = ({
  type = 'toast',
  appearance = '',
  status = true,
  heading = '',
  message = '',
  timeout = 0,
  classname = '',
  leftButtons,
  rightButtons,
} = {}) => {
  return async (dispatch) => {
    dispatch({
      type: 'SHOW_TOAST_MESSAGE',
      payload: {
        type,
        appearance,
        status,
        heading,
        message,
        timeout,
        classname,
        leftButtons,
        rightButtons,
      },
    });
  };
};

export const hideToastMessage = () => {
  return async (dispatch) => {
    dispatch({
      type: 'HIDE_TOAST_MESSAGE',
    });
  };
};
