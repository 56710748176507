import { GAEvent_getProductsData, GAProductsDataTypes, GAPageType, GASelectedArticleDataTypes } from '../GAhelpers';

/**
 * This function is typically called when the "Add to Cart" button is clicked from various pages as product detail page, wishlist page and cart, and the required data is passed as props.
 * It is integrated with the click event of the button to capture the relevant product information dynamically.
 *
 * @param {Object} activeProduct - The product details of the item that is added to the cart
 * @param {number} quantity - The quantity of the product selected while the item is added to the cart
 * @param {Object} selectedArticle - The size and articleIds of the selected or displayed product
 */

export const GAEvent_addToCart = (
  activeProduct: GAProductsDataTypes[0],
  quantity?: number,
  selectedArticle?: GASelectedArticleDataTypes,
) => {
  try {
    const eventData = GAEvent_getProductsData({
      cartLines: [activeProduct],
      quantity: quantity,
      selectedArticle: selectedArticle,
    });

    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: 'add_to_cart',
      ecommerce: {
        currency: 'INR',
        value: activeProduct?.priceForFront?.finalPrice || activeProduct?.sellingPrice || '',
        items: eventData?.GA4EventData,
        'product details': eventData?.amplitudeEventData,
        'add location': GAPageType(),
      },
    });
  } catch {}
};
