import { fetchProductSiblings, getMultiBuyProductStock } from './ProductPage';
import { addToCart, getCartCount, updateCartPincode } from './CartPage';
import { GAEvent_addToCart } from '@/redux/actions/GAEventsActions/globalEvents/GAaddToCart';
import { GASelectedArticleDataTypes } from '@/redux/actions/GAEventsActions/GAhelpers';
import { showToastMessage } from './toastMessageActions';
import fetchApi from '../../src/Middleware/fetchApi';
import { removeFromWishlist } from './WishlistPage';
import getProductStockAMI from '@/axios/getAmiProductsStock';
import { DY_AddToCart } from '@/src/lib/dynamicYield/events';

/**
 * Add item to cart based on model id
 *
 * @param {number} modelId - Product sku id
 * @param {object} productData - in case if we have product data in the page, then passing through productData we can avoid the api call.
 */

type addItemToCartGlobalType = {
  modelId: number;
  productData?: {
    articleIds: number[];
    articles: {
      articleId: number;
      attribute: {
        attributeName: string;
        attributeValue: string;
        attributeIndianValue: string;
        attributeSAPValue: string;
      };
      isDecathlonProduct: boolean;
      skuArticleId: string;
      default: boolean;
    }[];
    brand: string;
    description: {
      productName: string;
      descriptionShort: string;
    };
    isDecathlonProduct: boolean;
    modelId: number;
    priceForFront: {
      finalPrice: number;
      slashedPrice: number;
      discountPercentage: number;
      sticker: {
        name: string;
        image: string;
      };
      mrp: number;
      showSlash: boolean;
    };
    sizeGuide: number;
    stockNotification: {
      notify: boolean;
      message: string;
    };
  };
  showPopUp?: boolean;
  addToCartProductId?: number;
  addToCartFromNonGenericProductCard?: boolean;
  showQuantitySelector?: boolean;
  hideColorOptions?: boolean;
};

export const showAddToCartPopUp = (
  showPopUp: boolean,
  addToCartProductId: boolean = false,
  addToCartFromNonGenericProductCard: boolean = false,
) => {
  return async (dispatch: any, getState: any) => {
    dispatch({
      type: 'AITC_SHOW_POPUP',
      payload: showPopUp,
    });

    if (addToCartProductId) {
      dispatch({
        type: 'AITC_ADD_TO_CART_PROUDUCTID',
        payload: null,
      });
    }

    if (addToCartFromNonGenericProductCard) {
      dispatch({
        type: 'AITC_ADD_TO_CART_FROM_NON_GENERIC_PRODUCT_CARD',
        payload: false,
      });
    }
  };
};

export const addItemToCartFetchStockGlobal = (activeProduct: any) => {
  return async (dispatch: any, getState: any) => {
    dispatch({
      type: 'AITC_CHNAGE_COLOR_LOADER',
      payload: true,
    });
    const isDecathlonProduct = activeProduct?.isDecathlonProduct;
    const itemIds = isDecathlonProduct
      ? activeProduct.articleIds
      : activeProduct.articles.map((article: any) => article.skuArticleId);
    const response = isDecathlonProduct
      ? await dispatch(getMultiBuyProductStock(activeProduct?.articleIds))
      : await getProductStockAMI(itemIds, activeProduct);
    if (!Array.isArray(response)) {
      dispatch(
        // @ts-ignore
        showToastMessage({
          type: 'toast',
          appearance: 'error',
          message:
            'We are sorry!, unexpected error is occurred while fetching product stock, please try after some time later.',
          timeout: 3000,
        }),
      );
    }
    const productArticles = activeProduct?.articles?.map((article: any) => {
      const articleStockPicture =
        response?.length > 0 &&
        response?.find((itemStock: any) =>
          activeProduct?.isDecathlonProduct
            ? parseInt(itemStock?.itemId, 10) === article?.articleId
            : itemStock?.itemId === article?.skuArticleId,
        );

      return {
        ...article,
        fulfillmentCenter: articleStockPicture?.fulfilmentCenters?.[0]?.fulfillmentCenter,
        itemQuantity: articleStockPicture?.fulfilmentCenters?.[0]?.itemQuantity,
        isStockAvailable: articleStockPicture?.isStockAvailable || false,
      };
    });

    dispatch({
      type: 'AITC_STOCK_UPDATE',
      payload: productArticles,
    });

    return productArticles;
  };
};

export const addItemToCartAddToCart = (
  itemCode?: string,
  quantity?: number,
  selectedArticle?: GASelectedArticleDataTypes,
) => {
  return async (dispatch: any, getState: any) => {
    const { activeProduct } = getState()?.addItemToCartReducer || {};
    const postalCode = getState()?.reducer.pincode || {};
    const wishlistProductsInStock = getState()?.wishlistReducer.productsInStock || {};
    const selectedArticle = activeProduct?.articles.find(
      (article: any) => itemCode === (activeProduct.isDecathlonProduct ? article.articleId : article.skuArticleId),
    );

    if (quantity) {
      const itemQuantity = itemCode ? selectedArticle?.itemQuantity : activeProduct?.articles[0].itemQuantity;

      if (itemQuantity < quantity) {
        dispatch(
          // @ts-ignore
          showToastMessage({
            type: 'toast',
            appearance: 'info',
            message: `Only ${itemQuantity} quantity available in stock.`,
            timeout: 3000,
          }),
        );
        return false;
      }
    }

    dispatch({
      type: 'AITC_ADD_TO_CART_LOADING',
      payload: true,
    });

    const shoppingToolAction = localStorage.getItem('shoppingToolAction');
    const obj = shoppingToolAction && JSON.parse(shoppingToolAction);
    const addToCartResponse = await dispatch(
      // @ts-ignore
      addToCart({
        cartItems: [
          {
            itemCode:
              itemCode ||
              (activeProduct.isDecathlonProduct
                ? activeProduct.articles[0].articleId
                : activeProduct.articles[0].skuArticleId),
            lineType: 'DELIVERY',
            modelCode: Number(activeProduct?.modelId),
            quantity: quantity ?? 1,
            sellerId: activeProduct?.sellerDetail.sellerId,
            isDecathlon: activeProduct.isDecathlonProduct,
            analytics: {
              shoppingToolType: obj && obj.st,
              shoppingToolValue: obj && obj.std,
              shoppingToolSubValue: obj && obj.subVal,
            },
          },
        ],
      }),
    );
    dispatch(showAddToCartPopUp(false));
    if (addToCartResponse?.status) {
      dispatch(getCartCount());

      if (window.location.pathname === '/cart') {
        // @ts-ignore
        dispatch(updateCartPincode({ isCartPage: true, postalCode }));
      }
      if (window.location.pathname === '/wishlist') {
        // @ts-ignore
        const wishlistResponse = await dispatch(removeFromWishlist(Number(activeProduct?.modelId)));

        if (wishlistResponse?.status) {
          // @ts-ignore
          dispatch({
            type: 'WISHLIST_UPDATED_AFTER_DELETE_ITEM',
            payload: {
              pim: wishlistProductsInStock?.pim.filter((item: any) => item.modelId !== String(activeProduct?.modelId)),
              wcms: wishlistProductsInStock?.wcms.filter(
                (item: any) => item?.fields?.modelcode !== String(activeProduct?.modelId),
              ),
            },
          });
        } else {
          dispatch(
            // @ts-ignore
            showToastMessage({
              type: 'toast',
              appearance: 'error',
              message: 'Unable to remove item from wishlist',
              timeout: 3000,
            }),
          );
        }
      }

      dispatch(
        // @ts-ignore
        showToastMessage({
          type: 'toast',
          appearance: 'success',
          message: 'Product added to cart',
          timeout: 3000,
        }),
      );
      GAEvent_addToCart(activeProduct, quantity ?? 1, selectedArticle);
      DY_AddToCart({
        value: selectedArticle?.priceForFront?.finalPrice || activeProduct.articles[0]?.priceForFront?.finalPrice,
        productId: activeProduct.modelId,
        quantity: 1,
        size: selectedArticle?.attribute?.attributeValue || activeProduct.articles[0]?.attribute?.attributeValue,
        cart: [
          {
            productId: activeProduct.modelId,
            quantity: 1,
            itemPrice:
              selectedArticle?.priceForFront?.finalPrice || activeProduct.articles[0]?.priceForFront?.finalPrice,
          },
        ],
      });
    } else {
      dispatch(
        // @ts-ignore
        showToastMessage({
          type: 'toast',
          appearance: 'error',
          message: 'Some issue occurred, please try after some time later.',
          timeout: 3000,
        }),
      );
    }

    dispatch({
      type: 'AITC_ADD_TO_CART_LOADING',
      payload: false,
    });
    dispatch({
      type: 'AITC_ADD_TO_CART_PROUDUCTID',
      payload: null,
    });
    dispatch({
      type: 'AITC_ADD_TO_CART_FROM_NON_GENERIC_PRODUCT_CARD',
      payload: false,
    });
  };
};

export const addItemToCartGlobal = ({
  modelId,
  productData,
  showPopUp,
  addToCartProductId,
  addToCartFromNonGenericProductCard,
  showQuantitySelector,
  hideColorOptions,
}: addItemToCartGlobalType) => {
  return async (dispatch: any, getState: any) => {
    let productResponse = [];
    dispatch({
      type: 'AITC_PRODUCT_POPUP_CONFIG',
      payload: { showQuantitySelector, hideColorOptions },
    });
    dispatch({
      type: 'AITC_PRODUCT_DETAILS_LOADER',
      payload: true,
    });
    dispatch({
      type: 'AITC_ADD_TO_CART_PROUDUCTID',
      payload: addToCartProductId,
    });
    if (addToCartFromNonGenericProductCard)
      dispatch({
        type: 'AITC_ADD_TO_CART_FROM_NON_GENERIC_PRODUCT_CARD',
        payload: addToCartFromNonGenericProductCard,
      });

    if (productData && Object?.keys(productData)?.length) {
      productResponse = [productData];
    } else {
      const response = await fetchProductSiblings({ ctx: {}, modelId });
      if (response?.length) {
        productResponse = response;
      }
    }
    const activeProduct = productResponse.find((item: any) => item.modelId === modelId);
    if (activeProduct) {
      if (productResponse?.length === 1 && activeProduct?.articles?.length === 1) {
        const productArticles = await dispatch(addItemToCartFetchStockGlobal(activeProduct));
        dispatch({
          type: 'AITC_PRODUCT_DETAILS',
          payload: {
            activeProduct,
            productColors: productResponse,
          },
        });
        if (!productArticles?.[0]?.isStockAvailable) {
          dispatch(
            // @ts-ignore
            showToastMessage({
              type: 'toast',
              appearance: 'error',
              message: 'Currently Product is out of stock',
              timeout: 3000,
            }),
          );

          dispatch({
            type: 'AITC_ADD_TO_CART_PROUDUCTID',
            payload: null,
          });

          dispatch({
            type: 'AITC_ADD_TO_CART_FROM_NON_GENERIC_PRODUCT_CARD',
            payload: false,
          });
          return false;
        }

        dispatch(addItemToCartAddToCart());
      } else {
        dispatch({
          type: 'AITC_PRODUCT_DETAILS',
          payload: {
            activeProduct,
            productColors: productResponse,
          },
        });
        dispatch(addItemToCartFetchStockGlobal(activeProduct));
        dispatch(showAddToCartPopUp(showPopUp));
      }
    } else {
      dispatch(
        // @ts-ignore
        showToastMessage({
          type: 'toast',
          appearance: 'error',
          message: 'We are sorry, something went wrong please try after some time later.',
          timeout: 3000,
        }),
      );
      dispatch({
        type: 'AITC_PRODUCT_DETAILS',
        payload: {
          activeProduct: {},
          productColors: [],
        },
      });
      dispatch({
        type: 'AITC_ADD_TO_CART_PROUDUCTID',
        payload: null,
      });
      dispatch({
        type: 'AITC_ADD_TO_CART_FROM_NON_GENERIC_PRODUCT_CARD',
        payload: false,
      });
      return;
    }
  };
};

export const addItemToCartChangeColorGlobal = (modelId: number) => {
  return async (dispatch: any, getState: any) => {
    const { productColors } = getState()?.addItemToCartReducer || {};

    const activeProduct = productColors.find((item: any) => item.modelId === modelId);
    dispatch({
      type: 'AITC_CHNAGE_COLOR',
      payload: {
        activeProduct,
      },
    });

    dispatch(addItemToCartFetchStockGlobal(activeProduct));
  };
};

export const notifyMe = (articleId: string, email?: string) => {
  return async (dispatch: any) => {
    dispatch({
      type: 'AITC_NOTIFY_ME_LOADER',
      payload: true,
    });

    const response: any = await fetchApi(null, `/api/product/notify-me`, {
      data: {
        articleId,
        email,
      },
    });

    if (response?.status) {
      dispatch(
        // @ts-ignore
        showToastMessage({
          type: 'toast',
          appearance: 'success',
          message: response.message,
          timeout: 3000,
        }),
      );

      window.dataLayer.push({
        event: 'wishlist_notify',
        eventLabel: 'Wishlist',
        eventCategory: 'Wishlist',
        eventAction: 'Notify Me',
        ProductInStock: 'No',
      });
    } else {
      dispatch(
        // @ts-ignore
        showToastMessage({
          type: 'toast',
          appearance: 'error',
          message: response?.message || 'Something Went Wrong',
          timeout: 3000,
        }),
      );
    }

    dispatch({
      type: 'AITC_NOTIFY_ME_LOADER',
      payload: false,
    });
  };
};
